import React, { useEffect, useState, Fragment, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import DeleteIcon from "@mui/icons-material/Delete";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import { Bar } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextField from "@mui/material/TextField";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import { Chart, registry } from "chart.js/auto";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Fab } from "@mui/material";
import Fade from "@mui/material/Fade";
import AddIcon from "@mui/icons-material/Add";
import PublishIcon from "@mui/icons-material/Publish";
import Drawer from "@mui/material/Drawer";
import Checkbox from "@mui/material/Checkbox";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import debounce from "lodash/debounce";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3182bd",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function Dashboard() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [rankersList, setRankersList] = useState([]);
  const [importantDatesList, setimportantDatesList] = useState([]);
  const [selectedNoti, setSelectedNoti] = useState("EVERYONE");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [holidayList, setHolidayList] = useState([]);
  const [teacherLeaveRequests, setTeacherLeaveRequests] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [open, setOpen] = useState(false);
  const [uploadAttachment, setUploadAttachment] = useState(false);
  const [institutionInfo, setInstitutionInfo] = useState({
    teacher_count: 0,
    manager_count: 0,
    student_count: 0,
    parent_count: 0,
  });

  const handleNotiSelect = (event) => {
    setSelectedNoti(event.currentTarget.value);
  };

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
  };

  const [classList, setClassList] = useState([]);
  const [classs, setClass] = useState("");
  const [division, setDivision] = useState("");
  const [noticeFor, setNoticeFor] = useState("Everyone");

  function getNoticeForList() {
    var noticeForMenu = [
      "Everyone",
      "Teachers",
      "Parents",
      "Students",
      "Class",
      "Division",
    ].map((notiFor, index) => <MenuItem value={notiFor}>{notiFor}</MenuItem>);
    return noticeForMenu;
  }

  function getClassList() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionList() {
    var classes = Object.keys(classList[classs]).map((division, index) => (
      <MenuItem value={division}>{division}</MenuItem>
    ));
    return classes;
  }

  function sendNotification() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["send_to"] = selectedNoti;
    body["title"] = subject;
    body["message"] = message;
    body["class_id"] = classs;
    body["division"] = division;
    body["email"] = selectedSearch.length == 0 ? "null" : selectedSearch;

    axios
      .post(API_URL + "/send_notification_principal", body, config)
      .then((res) => {
        createAlert("SUCCESS", "Sent.", "Notification sent!");
      });
  }

  function handleNoticeForChange(e) {
    setNoticeFor(e.target.value);
  }

  function handleClassChange(e) {
    setClass(e.target.value);
  }

  function handleDivisionChange(e) {
    setDivision(e.target.value);
  }

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "HOME"
  );

  const [toggleDrawer, setToggleDrawer] = useState(false);
  const [noticeBoard, setNoticeBoard] = useState({});
  const [search, setSearch] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedSearch, setSelectedSearch] = useState("");

  function fileData() {
    if (selectedFile) {
      return (
        <div>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton size="small">
              <CloseIcon
                onClick={() => setOpen(false)}
                style={{ color: "#3182bd" }}
              />
            </IconButton>
          </div>
          <h2>File Details:</h2>
          <p>File Name: {selectedFile.name}</p>
          <p>File Type: {selectedFile.type}</p>
          <br></br>
          <br></br>
          {selectedFile.size > 25 * 1024 * 1024 ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                onClick={() => {
                  createAlert(
                    "ERROR",
                    "File too big.",
                    "File size should be less than 25 MB."
                  );
                  setOpen(false);
                }}
                variant="contained"
                style={{ backgroundColor: "#ddd" }}
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                onClick={() => onFileUpload()}
                color="primary"
              >
                <PublishIcon />
                &nbsp;&nbsp; UPLOAD DOCUMENTS
              </Button>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <h4>Choose before Pressing the Upload button</h4>
        </div>
      );
    }
  }

  function onFileUpload() {
    setIsSubmitting(true);
    const formData = new FormData();

    formData.append("file", selectedFile, selectedFile.name);

    console.log(selectedFile);

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
      },
    };

    formData.append("name", name);
    formData.append("description", description);
    formData.append("institution_id", state.auth.user.institution_id);
    formData.append("date", moment(Date.now()).format("DD-MM-YYYY"));
    formData.append("class_id", classs);
    formData.append("division", division);
    formData.append("notice_for", noticeFor);

    axios
      .post(API_URL + "/add_notice_with_attachment", formData, config)
      .then(() => {
        getData();
        setIsSubmitting(false);
        setToggleDrawer(false);
        createAlert("SUCCESS", "Success.", "Notice added.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });

    setOpen(false);
    document.getElementById("contained-button-file").value = "";
  }

  function addNotice() {
    setIsSubmitting(true);
    var body = {};

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["name"] = name;
    body["description"] = description;
    body["institution_id"] = state.auth.user.institution_id;
    body["date"] = moment(Date.now()).format("DD-MM-YYYY");
    body["class_id"] = classs;
    body["division"] = division;
    body["notice_for"] = noticeFor;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "/add_notice", body, config)
      .then(() => {
        getData();
        setIsSubmitting(false);
        setToggleDrawer(false);
        createAlert("SUCCESS", "Success.", "Notice added.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });

    setOpen(false);
  }

  function deleteNotice(category, division, id) {
    setIsSubmitting(true);
    var body = {};

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["institution_id"] = state.auth.user.institution_id;
    body["category"] = category;
    body["division"] = division;
    body["id"] = id;

    body = JSON.stringify(body);

    axios
      .post(API_URL + "/delete_notice", body, config)
      .then(() => {
        getData();
        setIsSubmitting(false);
        setToggleDrawer(false);
        createAlert("SUCCESS", "Success.", "Notice deleted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios.post(API_URL + "/get_events_list", body, config).then((res) => {
      setHolidayList(res.data["holidays_list"]);
      setimportantDatesList(res.data["important_dates_list"]);
      var tempEventsList = res.data["events_list"];
      for (var i = res.data["events_list"].length - 1; i >= 0; i--) {
        if (
          moment(res.data["events_list"][i]["date"], "DD-MM-YYYY").isBefore(
            moment()
          )
        ) {
          tempEventsList.splice(i, 1);
        }
      }

      setEventsList(tempEventsList);
      axios.post(API_URL + "/get_top_rankers", body, config).then((res) => {
        setRankersList(res.data);

        axios
          .post(API_URL + "/get_leave_requests_principal", body, config)
          .then((res) => {
            var teacherpending = 0;
            var teacherapproved = 0;
            var teacherrejected = 0;

            for (var i = 0; i < res.data["teacher_requests"].length; i++) {
              if (res.data["teacher_requests"][i]["status"] == "PENDING") {
                teacherpending += 1;
              } else if (
                res.data["teacher_requests"][i]["status"] == "APPROVED"
              ) {
                teacherapproved += 1;
              } else if (
                res.data["teacher_requests"][i]["status"] == "REJECTED"
              ) {
                teacherrejected += 1;
              }
            }

            setTeacherLeaveRequests({
              PENDING: teacherpending,
              APPROVED: teacherapproved,
              REJECTED: teacherrejected,
            });
            axios
              .post(API_URL + "/get_noticeboard_principal", body, config)
              .then((res) => {
                axios
                  .post(API_URL + "/get_institution_info", body, config)
                  .then((res) => {
                    setInstitutionInfo(res.data);
                    setIsLoading(false);
                  });
                setNoticeBoard(res.data);
              });
          });
      });
    });
  }

  function onFileChange(event) {
    setSelectedFile(event.target.files[0]);
    setOpen(true);
  }

  function onFileClick(event) {
    document.getElementById("contained-button-file").value = "";
  }

  function getNoticeForMessage(noti) {
    if (noti == "Everyone") {
      return <span>Notice will be sent to everyone.</span>;
    } else if (noti == "Teachers") {
      return <span>Notice will be sent to all the teachers.</span>;
    } else if (noti == "Parents") {
      return <span>Notice will be sent to all the parents.</span>;
    } else if (noti == "Students") {
      return <span>Notice will be sent to all the students.</span>;
    } else if (noti == "Class") {
      return (
        <span>
          Notice will be sent to parents, students, teachers of {classs}.
        </span>
      );
    } else if (noti == "Division") {
      return (
        <span>
          Notice will be sent to parents, students, teachers of {classs}
          {division}
        </span>
      );
    }
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      body["institution_id"] = state.auth.user.institution_id;

      body = JSON.stringify(body);
      axios.post(API_URL + "/get_class_list", body, config).then((res) => {
        var classListTemp = res.data;
        setClassList(classListTemp);
        setClass(Object.keys(classListTemp)[0]);
        setDivision(
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
        setIsReady(true);
        getData();
      });
    }
  }, []);

  const onSearch = (value) => {
    setSearch(value);
    handleSearch(value);
  };

  const handleSearch = useCallback(
    debounce((value) => {
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      axios
        .get(
          API_URL +
            "/search_users?institution_id=" +
            state.auth.user.institution_id +
            "&search=" +
            value,
          config
        )
        .then((res) => {
          console.log(res);

          setSearchResults(res.data);
        })
        .catch((err) => {
          setSearchResults([]);
        });
    }, 1000),
    []
  );

  if (state.auth.refresh == "REFRESH_DATA") {
    getData();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  var colorIndex = 0;

  const graphState = {
    dataLine: {
      labels: [
        "1st",
        "2nd",
        "3rd",
        "4th",
        "5th",
        "6th",
        "7th",
        "8th",
        "9th",
        "10th",
        "11th",
        "12th",
      ],
      datasets: [
        {
          label: "Students passed",
          fill: true,
          lineTension: 0.3,
          borderRadius: 100,
          borderSkipped: false,
          backgroundColor: "#084594",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          data: [65, 59, 80, 81, 56, 55, 40, 80, 81, 56, 55, 40],
          barThickness: 20,
        },
      ],
    },
  };

  const colorList = ["#a1d99b", "#9ecae1", "#fdae6b"];

  function getContent() {
    if (selectedPage == "HOME") {
      return (
        <Fade in={true}>
          <Grid container spacing={5}>
            <Grid item md={8}>
              {/* <h4>Pass Percentage</h4>
            <Bar
              height={120}
              width={250}
              data={graphState.dataLine}
              options={{
                legend: {
                  display: false,
                },
                responsive: true,
                animation: { duration: 0 },
              }}
            /> */}
              <Grid container spacing={3} style={{ marginTop: "10px" }}>
                <Grid item md={3}>
                  <Link
                    style={{ textDecoration: "none", color: "#3182bd" }}
                    to="/approvals?tab=PROFILES"
                  >
                    <CardActionArea
                      style={{
                        textAlign: "center",
                        height: "180px",
                        borderBottom: "5px solid " + colorList3[1],
                        borderTop: "1px solid " + colorList3[1],
                        borderLeft: "1px solid " + colorList3[1],
                        borderRight: "1px solid " + colorList3[1],
                        borderRadius: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Lato-Bold",
                          fontSize: "16px",
                        }}
                      >
                        Teachers
                      </p>

                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "43px",
                          color: colorList2[1],
                        }}
                      >
                        {institutionInfo["teacher_count"]}
                      </p>
                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                          color: "#3182bd",
                        }}
                      >
                        View All
                      </div>
                    </CardActionArea>
                  </Link>
                </Grid>
                <Grid item md={3}>
                  <Link
                    style={{ textDecoration: "none", color: "#3182bd" }}
                    to="/students"
                  >
                    <CardActionArea
                      style={{
                        textAlign: "center",
                        height: "180px",
                        borderBottom: "5px solid " + colorList3[2],
                        borderTop: "1px solid " + colorList3[2],
                        borderLeft: "1px solid " + colorList3[2],
                        borderRight: "1px solid " + colorList3[2],
                        borderRadius: "5px",
                        marginBottom: "5px",
                      }}
                    >
                      <p
                        style={{
                          fontFamily: "Lato-Bold",
                          fontSize: "16px",
                        }}
                      >
                        Students
                      </p>

                      <p
                        style={{
                          marginTop: "10px",
                          fontSize: "43px",
                          color: colorList2[2],
                        }}
                      >
                        {institutionInfo["student_count"]}
                      </p>
                      <div
                        style={{
                          position: "absolute",
                          bottom: 10,
                          right: 10,
                          color: "#3182bd",
                        }}
                      >
                        View All
                      </div>
                    </CardActionArea>
                  </Link>
                </Grid>

                <Grid item md={3}>
                  <CardActionArea
                    style={{
                      textAlign: "center",
                      height: "180px",
                      borderBottom: "5px solid " + colorList3[3],
                      borderTop: "1px solid " + colorList3[3],
                      borderLeft: "1px solid " + colorList3[3],
                      borderRight: "1px solid " + colorList3[3],
                      borderRadius: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: "16px",
                      }}
                    >
                      Parents
                    </p>

                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "43px",
                        color: colorList2[3],
                      }}
                    >
                      {institutionInfo["parent_count"]}
                    </p>
                  </CardActionArea>
                </Grid>
                <Grid item md={3}>
                  <CardActionArea
                    style={{
                      textAlign: "center",
                      height: "180px",
                      borderBottom: "5px solid " + colorList3[4],
                      borderTop: "1px solid " + colorList3[4],
                      borderLeft: "1px solid " + colorList3[4],
                      borderRight: "1px solid " + colorList3[4],
                      borderRadius: "5px",
                      marginBottom: "5px",
                    }}
                  >
                    <p
                      style={{
                        fontFamily: "Lato-Bold",
                        fontSize: "16px",
                      }}
                    >
                      Principals
                    </p>

                    <p
                      style={{
                        marginTop: "10px",
                        fontSize: "43px",
                        color: colorList2[4],
                      }}
                    >
                      {institutionInfo["manager_count"]}
                    </p>
                  </CardActionArea>
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item md={6}>
                  <h4>Send Notification</h4>
                  <Grid container spacing={1}>
                    <Grid item md={4}>
                      {selectedNoti == "EVERYONE" ? (
                        <Button
                          fullWidth
                          value="EVERYONE"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            marginTop: "-15px",
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "25px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              letterSpacing: "1.5px",
                            }}
                          >
                            EVERYONE
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="EVERYONE"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            height: "25px",
                            marginTop: "-15px",
                            backgroundColor: "#d7ecff",
                            color: "white",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            EVERYONE
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedNoti == "STUDENTS" ? (
                        <Button
                          fullWidth
                          value="STUDENTS"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            marginTop: "-15px",
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "25px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              letterSpacing: "1.5px",
                            }}
                          >
                            STUDENTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="STUDENTS"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            height: "25px",
                            marginTop: "-15px",
                            backgroundColor: "#d7ecff",
                            color: "white",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            STUDENTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedNoti == "TEACHERS" ? (
                        <Button
                          fullWidth
                          value="TEACHERS"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            marginTop: "-15px",
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "25px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              letterSpacing: "1.5px",
                            }}
                          >
                            TEACHERS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="TEACHERS"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            height: "25px",
                            marginTop: "-15px",
                            backgroundColor: "#d7ecff",
                            color: "white",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            TEACHERS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedNoti == "CLASS" ? (
                        <Button
                          fullWidth
                          value="CLASS"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            marginTop: "-15px",
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "25px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              letterSpacing: "1.5px",
                            }}
                          >
                            CLASS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="CLASS"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            height: "25px",
                            marginTop: "-15px",
                            backgroundColor: "#d7ecff",
                            color: "white",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            CLASS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedNoti == "PARENTS" ? (
                        <Button
                          fullWidth
                          value="PARENTS"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            marginTop: "-15px",
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "25px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              letterSpacing: "1.5px",
                            }}
                          >
                            PARENTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="PARENTS"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            height: "25px",
                            marginTop: "-15px",
                            backgroundColor: "#d7ecff",
                            color: "white",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            PARENTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedNoti == "SEARCH" ? (
                        <Button
                          fullWidth
                          value="SEARCH"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            marginTop: "-15px",
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "25px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              letterSpacing: "1.5px",
                            }}
                          >
                            SEARCH
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="SEARCH"
                          onClick={handleNotiSelect}
                          p={2}
                          style={{
                            height: "25px",
                            marginTop: "-15px",
                            backgroundColor: "#d7ecff",
                            color: "white",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            SEARCH
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <br></br>
                  {selectedNoti == "CLASS" ? (
                    <Fragment>
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <Select
                            value={classs}
                            onChange={(e) => handleClassChange(e)}
                            style={{
                              height: "40px",
                            }}
                            fullWidth
                            variant={"outlined"}
                          >
                            {getClassList()}
                          </Select>
                        </Grid>{" "}
                        <Grid item xs={6}>
                          <Select
                            value={division}
                            onChange={(e) => handleDivisionChange(e)}
                            style={{
                              height: "40px",
                            }}
                            fullWidth
                            variant={"outlined"}
                          >
                            {getDivisionList()}
                          </Select>
                        </Grid>
                      </Grid>
                      <br></br>
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  {selectedNoti == "SEARCH" ? (
                    <Fragment>
                      <Autocomplete
                        id="google-map-demo"
                        getOptionLabel={
                          (option) =>
                            option.name == null ? option : option.name

                          // option['name']
                        }
                        options={searchResults}
                        autoComplete
                        includeInputInList
                        value={search}
                        noOptionsText="No results"
                        onChange={(e) => {}}
                        onInputChange={(event, newInputValue) => {
                          onSearch(newInputValue);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            size="small"
                            label="Search email or name..."
                            fullWidth
                          />
                        )}
                        renderOption={(props, option) => {
                          return (
                            <li {...props}>
                              <Grid
                                container
                                alignItems="center"
                                onClick={() => {
                                  setSelectedSearch(option.email);
                                }}
                              >
                                <Grid item sm={1}>
                                  <Avatar
                                    alt={option["email"]}
                                    src={API_URL + "/media/" + option["image"]}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  sm={10}
                                  sx={{ wordWrap: "break-word" }}
                                >
                                  <div
                                    style={{
                                      margin: "0px 0px 0px 20px",
                                      fontFamily: "Lato-Bold",
                                      fontSize: "18px",
                                      color: "#3182bd",
                                    }}
                                  >
                                    {option["name"]}
                                  </div>
                                  <div
                                    style={{
                                      margin: "-5px 0px -5px 20px",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {option["subtext"]}
                                  </div>
                                  <div
                                    style={{
                                      margin: "0px 0px 0px 20px",
                                      fontSize: "16px",
                                      color: "grey",
                                    }}
                                  >
                                    {option["email"]}
                                  </div>
                                </Grid>
                              </Grid>
                            </li>
                          );
                        }}
                      />

                      <br />
                    </Fragment>
                  ) : (
                    <Fragment></Fragment>
                  )}
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Subject"
                    variant="outlined"
                    value={subject}
                    size="small"
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <br></br> <br></br>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    placeholder="Message"
                    variant="outlined"
                    size="small"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <br></br> <br></br>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Button
                      style={{
                        fontSize: "15px",
                        marginTop: "-5px",
                        height: "35px",
                        backgroundColor: "#3182bd",
                      }}
                      onClick={() => sendNotification()}
                      variant="contained"
                      color="primary"
                    >
                      <span
                        style={{
                          marginTop: "3px",
                        }}
                      >
                        Send
                      </span>
                      <ChevronRightIcon
                        style={{
                          fontSize: "30px",
                          color: "white",
                          marginRight: "-10px",
                        }}
                      />
                    </Button>
                  </div>
                </Grid>
                <Grid item md={6}>
                  {eventsList.length != 0 ? (
                    <h4>Upcoming events</h4>
                  ) : (
                    <Fragment>
                      <h4>No Upcoming Events</h4>
                      <Link
                        to="/events"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        <div
                          style={{
                            marginTop: "150px",
                            height: "100px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <div style={{ textAlign: "center" }}>
                            <Fab
                              size="large"
                              style={{
                                border: "3px solid #aaa",
                                boxShadow: "none",
                                backgroundColor: "transparent",
                              }}
                              aria-label="add"
                            >
                              <PriorityHighIcon
                                style={{
                                  color: "grey",
                                }}
                              />
                            </Fab>
                            <br />
                            <h4 style={{ color: "grey" }}>
                              No upcoming Exams.
                            </h4>

                            <h4 style={{ color: "grey" }}>
                              Click Here to go to events page.
                            </h4>
                          </div>
                        </div>
                      </Link>
                    </Fragment>
                  )}
                  <div style={{ marginTop: "-15px" }}>
                    {eventsList.slice(0, 3).map((event, index) => (
                      <Link
                        to="/events"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        <span style={{ display: "none" }}>
                          {
                            (colorIndex = Math.floor(
                              Math.random() * colorList.length
                            ))
                          }
                        </span>
                        <CardActionArea
                          style={{
                            height: "80px",
                            borderLeft: "5px solid " + colorList3[colorIndex],
                            borderRadius: "5px",
                            marginBottom: "5px",
                          }}
                        >
                          <Grid
                            container
                            spacing={1}
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <Grid
                              item
                              md={8}
                              style={{
                                marginTop: "-3px",
                                marginLeft: "5px",
                              }}
                            >
                              <p
                                style={{
                                  margin: "0px 0px 3px 10px",
                                  fontFamily: "Lato-Bold",
                                  fontSize: "16px",
                                }}
                              >
                                {event["name"]}
                              </p>

                              <p
                                style={{
                                  margin: "0px 0px 5px 10px",
                                  fontSize: "13px",
                                }}
                              >
                                {moment(event["date"], "DD-MM-YYYY").format(
                                  "Do MMMM, YYYY"
                                )}
                              </p>
                              <p
                                style={{
                                  margin: "-3px 0px 0px 10px",
                                  fontSize: "15px",
                                  color: colorList2[colorIndex],
                                }}
                              >
                                {event["venue"]}
                              </p>
                            </Grid>
                            <Grid item>
                              <ChevronRightIcon
                                style={{
                                  fontSize: "40px",
                                  color: colorList3[colorIndex],
                                }}
                              />
                            </Grid>
                          </Grid>
                        </CardActionArea>
                      </Link>
                    ))}
                    {eventsList.length >= 3 ? (
                      <Link
                        to="/events"
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            textAlign: "right",
                            color: "#3182bd",
                          }}
                        >
                          <h4>More Events...</h4>
                        </div>
                      </Link>
                    ) : (
                      ""
                    )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
            <br></br>
            <Grid item md={4}>
              <h4>Teacher Leave Requests</h4>
              <div style={{ display: "flex", marginBottom: "30px" }}>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={{
                    pathname: "/approvals",
                    search: "?tab=TEACHERLEAVES",
                  }}
                >
                  <Fab
                    style={{
                      backgroundColor: "#31a354",
                      color: "white",
                      boxShadow: "none",
                    }}
                    size="large"
                  >
                    <h1>{teacherLeaveRequests["APPROVED"]}</h1>
                  </Fab>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={{
                    pathname: "/approvals",
                    search: "?tab=TEACHERLEAVES",
                  }}
                >
                  <Fab
                    style={{
                      backgroundColor: "#ff3333",
                      color: "white",
                      marginLeft: "10px",
                      boxShadow: "none",
                    }}
                    size="large"
                  >
                    <h1>{teacherLeaveRequests["REJECTED"]}</h1>
                  </Fab>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to={{
                    pathname: "/approvals",
                    search: "?tab=TEACHERLEAVES",
                  }}
                >
                  <Fab
                    style={{
                      backgroundColor: "#3182bd",
                      color: "white",
                      marginLeft: "10px",
                      boxShadow: "none",
                    }}
                    size="large"
                  >
                    <h1>{teacherLeaveRequests["PENDING"]}</h1>
                  </Fab>
                </Link>
              </div>

              <h4>Top Holistic Ranking</h4>

              {rankersList.length == 0 ? (
                <Fragment>
                  <div
                    style={{
                      marginTop: "50px",
                      marginBottom: "50px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>
                        Ranking data unavailable.
                      </h4>

                      <h4 style={{ color: "grey" }}>
                        Rank details added by students will appear here.
                      </h4>
                    </div>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {rankersList.map((ranker, index) => (
                    <Fragment>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Avatar
                          alt={ranker["name"]}
                          src={API_URL + "/media/" + ranker["image"]}
                        />
                        <Grid item md={8}>
                          <b>
                            <p style={{ margin: "0px 0px 0px 10px" }}>
                              {ranker["name"]}
                            </p>
                          </b>
                          <p style={{ margin: "0px 0px 0px 10px" }}>
                            #{ranker["rank"]}
                          </p>
                        </Grid>
                        <Grid item>
                          <span
                            style={{
                              fontSize: "20px",
                              color: "#fdae6b",
                            }}
                          >
                            {ranker["points"]} pt
                          </span>
                        </Grid>
                      </Grid>
                      <br></br>
                    </Fragment>
                  ))}
                </Fragment>
              )}
              <br></br>
              {importantDatesList.length != 0 ? (
                <h4>Important dates</h4>
              ) : (
                <Fragment>
                  <h4>No Upcoming important dates</h4>
                  <Link
                    to="/events"
                    style={{
                      textDecoration: "none",
                      color: "black",
                    }}
                  >
                    <div
                      style={{
                        marginTop: "50px",
                        height: "100px",
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div style={{ textAlign: "center" }}>
                        <Fab
                          size="large"
                          style={{
                            border: "3px solid #aaa",
                            boxShadow: "none",
                            backgroundColor: "transparent",
                          }}
                          aria-label="add"
                        >
                          <PriorityHighIcon
                            style={{
                              color: "grey",
                            }}
                          />
                        </Fab>
                        <br />
                        <h4 style={{ color: "grey" }}>
                          No upcoming important dates.
                        </h4>

                        <h4 style={{ color: "grey" }}>
                          Click Here to go to events page.
                        </h4>
                      </div>
                    </div>
                  </Link>
                </Fragment>
              )}
              {importantDatesList.slice(0, 4).map((date, index) => (
                <Link
                  to="/events"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <Box
                    border={1}
                    borderColor="#d2d2d2"
                    p={0.3}
                    fullWidth
                    height={30}
                    borderRadius="16px"
                    style={{
                      display: "flex",
                      backgroundColor: "white",
                      color: "black",
                      justifyContent: "space-between",
                    }}
                    alignItems="center"
                  >
                    <p
                      style={{
                        fontSize: "15px",
                        margin: "-0px 0px 0px 12px",
                      }}
                    >
                      {date["name"]}
                    </p>
                    <div
                      style={{
                        margin: "-3px 10px 0px 0px",
                        color: "black",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                        }}
                      >
                        {moment(date["date"], "DD-MM-YYYY").format(
                          "Do MMMM, YYYY"
                        )}
                      </span>
                    </div>
                  </Box>
                  <br></br>
                </Link>
              ))}
            </Grid>
          </Grid>
        </Fade>
      );
    } else {
      return (
        <Fragment>
          <Fab
            size="large"
            style={{
              boxShadow: "none",
              backgroundColor: "#ff3333 ",
              position: "fixed",
              bottom: 30,
              right: 30,
            }}
            onClick={() => setToggleDrawer(true)}
            aria-label="add"
          >
            <PublishIcon
              style={{
                color: "white",
              }}
            />
          </Fab>
          <Drawer
            anchor={"right"}
            open={toggleDrawer}
            onClose={() => setToggleDrawer(false)}
          >
            {isSubmitting ? (
              <div
                style={{
                  marginTop: "60px",
                  padding: "20px",
                  width: "400px",
                  backgroundColor: "white",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
                <h3>Uploading in progress...</h3>
              </div>
            ) : (
              <div
                style={{
                  marginTop: "40px",
                  padding: "20px",
                  width: "400px",
                }}
              >
                <span
                  style={{
                    color: "#3182bd",
                    fontFamily: "Lato-Regular",
                    fontSize: "14px",
                  }}
                >
                  Notice For
                </span>
                <Select
                  value={noticeFor}
                  onChange={(e) => handleNoticeForChange(e)}
                  style={{
                    marginTop: "2px",

                    height: "40px",
                  }}
                  fullWidth
                  variant={"outlined"}
                >
                  {getNoticeForList()}
                </Select>
                {noticeFor == "Class" || noticeFor == "Division" ? (
                  <Fragment>
                    <span
                      style={{
                        marginTop: "2px",

                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Class
                    </span>
                    <Select
                      value={classs}
                      onChange={(e) => handleClassChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getClassList()}
                    </Select>
                  </Fragment>
                ) : (
                  <span></span>
                )}
                {noticeFor == "Division" ? (
                  <Fragment>
                    <span
                      style={{
                        color: "#3182bd",
                        fontFamily: "Lato-Regular",
                        fontSize: "14px",
                      }}
                    >
                      Division
                    </span>
                    <Select
                      value={division}
                      onChange={(e) => handleDivisionChange(e)}
                      style={{
                        marginTop: "2px",
                        height: "40px",
                        marginBottom: "5px",
                      }}
                      fullWidth
                      variant={"outlined"}
                    >
                      {getDivisionList()}
                    </Select>
                  </Fragment>
                ) : (
                  <span></span>
                )}
                <div
                  style={{
                    marginTop: "5px",

                    color: "#3182bd",
                    fontFamily: "Lato-Regular",
                    fontSize: "14px",
                  }}
                >
                  Title
                </div>
                <TextField
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  fullWidth
                  size="small"
                  style={{
                    marginTop: "2px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                  variant={"outlined"}
                  placeholder="Notice Title"
                />
                <span
                  style={{
                    color: "#3182bd",
                    fontFamily: "Lato-Regular",
                    fontSize: "14px",
                  }}
                >
                  Description
                </span>
                <TextField
                  value={description}
                  style={{
                    marginTop: "2px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  placeholder="Description"
                  variant="outlined"
                  multiline
                  rows={4}
                />
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <br></br>
                <Checkbox
                  labelStyle={{ color: "#3182bd" }}
                  iconStyle={{ fill: "white" }}
                  inputStyle={{ color: "#3182bd" }}
                  style={{ margin: "0px 0px 2px -12px", color: "#3182bd" }}
                  checked={uploadAttachment}
                  onChange={(e) => setUploadAttachment(e.target.checked)}
                  value={1}
                  color={"primary"}
                />
                <span
                  style={{
                    color: "#3182bd",
                    fontFamily: "Lato-Regular",
                    fontSize: "14px",
                  }}
                >
                  Upload attachment
                </span>
                <br></br>
                <span
                  style={{
                    color: "#ff3333",
                    fontFamily: "Lato-Regular",
                    fontSize: "14px",
                  }}
                >
                  {getNoticeForMessage(noticeFor)}
                </span>
                <br></br>
                <br></br>
                <Box
                  style={{
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  {" "}
                  {uploadAttachment ? (
                    <label
                      style={{ width: "100%" }}
                      htmlFor="contained-button-file"
                    >
                      <input
                        style={{ display: "none", width: "100%" }}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => onFileChange(e)}
                      />

                      <Button
                        style={{
                          fontSize: "15px",
                          height: "35px",
                          marginTop: "-1px",
                          backgroundColor: "#3182bd",
                        }}
                        fullWidth
                        variant="contained"
                        component="span"
                      >
                        <h4>Send Notice</h4>
                      </Button>
                    </label>
                  ) : (
                    <Button
                      onClick={() => addNotice()}
                      fullWidth
                      style={{
                        fontSize: "15px",
                        height: "35px",
                        marginTop: "-1px",
                        backgroundColor: "#3182bd",
                      }}
                      variant="contained"
                      color="primary"
                    >
                      <h4>Send Notice</h4>
                    </Button>
                  )}
                </Box>
              </div>
            )}
          </Drawer>
          <div style={{ marginTop: "75px" }}>
            {Object.keys(noticeBoard).map((category) =>
              category == "everyone" ||
              category == "parents" ||
              category == "teachers" ||
              category == "students" ? (
                <Fragment>
                  {noticeBoard[category].length != 0 ? (
                    <Fragment>
                      <h4>
                        {category.charAt(0).toUpperCase() + category.slice(1)}
                      </h4>
                      <Grid container spacing={1.5}>
                        {noticeBoard[category].map((noti, index) => (
                          <Grid item md={3}>
                            <Box
                              padding={1}
                              borderRadius="5px"
                              sx={{ "&:hover": { opacity: "1 !important" } }}
                              style={{
                                borderRadius: "6px",
                                border: "2px solid #d7ecff",
                                backgroundColor: "white",
                                boxShadow: "none",
                                height: "100%",
                                marginBottom: "5px",
                              }}
                            >
                              <Box style={{ marginLeft: "5px" }}>
                                <Grid
                                  container
                                  spacing={2}
                                  justifyContent="space-between"
                                >
                                  <Grid item md={10}>
                                    <div
                                      style={{
                                        fontFamily: "Lato-Bold",
                                        color: "#084594",
                                        fontSize: "19px",
                                      }}
                                    >
                                      {noti["title"]}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "Lato-Regular",
                                      }}
                                    >
                                      {moment(
                                        noti["date"],
                                        "DD-MM-YYYY"
                                      ).format("Do MMMM, YYYY")}
                                    </div>
                                    <div
                                      style={{
                                        fontSize: "15px",
                                        fontFamily: "Lato-Bold",
                                      }}
                                    >
                                      {noti["venue"]}
                                    </div>
                                    <div
                                      style={{
                                        margin: "10px 0px 0px 0px",
                                        fontSize: "15px",
                                        fontFamily: "Lato-Regular",
                                      }}
                                    >
                                      {noti["desc"]}
                                    </div>
                                    {Object.keys(noti).includes("link") ? (
                                      <a
                                        href={noti["link"]}
                                        target="_blank"
                                        style={{
                                          margin: "10px 0px 0px 0px",
                                          fontSize: "15px",
                                          fontFamily: "Lato-Regular",
                                        }}
                                      >
                                        View Document
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </Grid>
                                  <Grid item md={2}>
                                    <Button
                                      style={{
                                        borderRadius: "50px",
                                        marginTop: "-10px",

                                        height: "50px",
                                        width: "50px",
                                      }}
                                      onClick={() => {
                                        deleteNotice(
                                          category,
                                          null,
                                          noti["id"]
                                        );
                                      }}
                                    >
                                      <DeleteIcon
                                        style={{
                                          color: "#ff3333",
                                        }}
                                      />
                                    </Button>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                    </Fragment>
                  ) : (
                    <h4>No notices issued for {category}.</h4>
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  {Object.keys(noticeBoard[category]).map((divi) => (
                    <Fragment>
                      {noticeBoard[category][divi].length != 0 ? (
                        <Fragment>
                          <h4>
                            {category.charAt(0).toUpperCase() +
                              category.slice(1) +
                              " " +
                              divi}
                          </h4>
                          <Grid container spacing={1.5}>
                            {noticeBoard[category][divi].map((noti, index) => (
                              <Grid item md={3}>
                                <Box
                                  padding={1}
                                  borderRadius="5px"
                                  sx={{
                                    "&:hover": { opacity: "1 !important" },
                                  }}
                                  style={{
                                    borderRadius: "6px",
                                    border: "2px solid #d7ecff",
                                    backgroundColor: "white",
                                    boxShadow: "none",
                                    height: "100%",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Box style={{ marginLeft: "5px" }}>
                                    <Grid
                                      container
                                      spacing={2}
                                      justifyContent="space-between"
                                    >
                                      <Grid item md={10}>
                                        <div
                                          style={{
                                            fontFamily: "Lato-Bold",
                                            color: "#084594",
                                            fontSize: "19px",
                                          }}
                                        >
                                          {noti["title"]}
                                        </div>
                                        <div
                                          style={{
                                            fontSize: "15px",
                                            fontFamily: "Lato-Regular",
                                          }}
                                        >
                                          {moment(
                                            noti["date"],
                                            "DD-MM-YYYY"
                                          ).format("Do MMMM, YYYY")}
                                        </div>
                                        <div
                                          style={{
                                            fontSize: "15px",
                                            fontFamily: "Lato-Bold",
                                          }}
                                        >
                                          {noti["venue"]}
                                        </div>
                                        <div
                                          style={{
                                            margin: "10px 0px 0px 0px",
                                            fontSize: "15px",
                                            fontFamily: "Lato-Regular",
                                          }}
                                        >
                                          {noti["desc"]}
                                        </div>
                                        {Object.keys(noti).includes("link") ? (
                                          <a
                                            href={noti["link"]}
                                            target="_blank"
                                            style={{
                                              margin: "10px 0px 0px 0px",
                                              fontSize: "15px",
                                              fontFamily: "Lato-Regular",
                                            }}
                                          >
                                            View Document
                                          </a>
                                        ) : (
                                          ""
                                        )}
                                      </Grid>
                                      <Grid item md={2}>
                                        <Button
                                          style={{
                                            borderRadius: "50px",
                                            marginTop: "-10px",

                                            height: "50px",
                                            width: "50px",
                                          }}
                                          onClick={() => {
                                            deleteNotice(
                                              category,
                                              divi,
                                              noti["id"]
                                            );
                                          }}
                                        >
                                          <DeleteIcon
                                            style={{
                                              color: "#ff3333",
                                            }}
                                          />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  </Box>
                                </Box>
                              </Grid>
                            ))}
                          </Grid>
                        </Fragment>
                      ) : (
                        <span></span>
                      )}
                    </Fragment>
                  ))}
                </Fragment>
              )
            )}
          </div>
        </Fragment>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading || !isReady) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  maxWidth: "88vw",
                  marginTop: "45vh",
                  marginLeft: "8vw",
                  backgroundColor: "white",
                  height: "100%",
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5} justifyContent={"flex-end"}>
                    <Grid item md={4}>
                      {selectedPage == "HOME" ? (
                        <Button
                          fullWidth
                          value={"HOME"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            HOME
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"HOME"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            HOME
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={4}>
                      {selectedPage == "NOTICEBOARD" ? (
                        <Button
                          fullWidth
                          value="NOTICEBOARD"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            NOTICEBOARD
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="NOTICEBOARD"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            NOTICEBOARD
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    {fileData()}
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  margin: "37px 10px 20px 90px",
                  backgroundColor: "white",
                }}
              >
                {getContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission denied.", "Please Login as manager");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Dashboard;
