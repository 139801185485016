import React, { useEffect, useState, useRef, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Paper from "@mui/material/Paper";
import Fab from "@mui/material/Fab";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import PublishIcon from "@mui/icons-material/Publish";
import Avatar from "@mui/material/Avatar";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { Link, Navigate, useNavigate, Redirect } from "react-router-dom";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import GavelIcon from "@mui/icons-material/Gavel";
import BallotIcon from "@mui/icons-material/Ballot";
import ComputerIcon from "@mui/icons-material/Computer";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PublicIcon from "@mui/icons-material/Public";
import CalculateIcon from "@mui/icons-material/Calculate";
import Calendar from "react-calendar";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import SaveIcon from "@mui/icons-material/Save";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Checkbox from "@mui/material/Checkbox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TouchRipple from "@mui/material/ButtonBase/TouchRipple";
import IconButton from "@mui/material/IconButton";
import ButtonBase from "@mui/material/ButtonBase";
import { te, tr } from "date-fns/locale";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3182bd",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function getIcon(subject) {
  var sub = subject.toLowerCase();
  if (sub.includes("break") || sub.includes("lunch")) {
    return <LocalDiningIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("mechanic")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("social")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("civics")) {
    return <GavelIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("polit")) {
    return <BallotIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("compu")) {
    return <ComputerIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("bio")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("chem") || sub.includes("scien")) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (
    sub.includes("economics") ||
    sub.includes("accou") ||
    sub.includes("busi")
  ) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (
    sub.includes("geom") ||
    sub.includes("archi") ||
    (sub.includes("engg") && sub.includes("draw"))
  ) {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  } else if (sub.includes("geo")) {
    return <PublicIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("math")) {
    return <CalculateIcon style={{ fontSize: 45, color: "black" }} />;
  } else if (sub.includes("history")) {
    return <HistoryIcon style={{ fontSize: 45, color: "black" }} />;
  } else {
    return (
      <MenuBookIcon
        style={{ fontSize: 43, color: "black", marginLeft: "2px" }}
      />
    );
  }
}

function Settings() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const [selectedPage, setSelectedPage] = useState("INSTITUTION");

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [promoteSubmitAnswer, setPromoteSubmitAnswer] = useState("NO");

  const [classList, setClassList] = useState([]);
  const [classListOld, setClassListOld] = useState([]);
  const [feesStructure, setFeesStructure] = useState({});

  const [classs, setClass] = useState("");
  const [division, setDivision] = useState("");
  const [info, setInfo] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false);
  const [timeTable, setTimeTable] = useState([]);
  const [itemIndex, setItemIndex] = useState(9999);
  const [random, setRandom] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [feesItemIndex, setFeesItemIndex] = useState(9999);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedClassesPage, setSelectedClassesPage] = useState("CLASSES");
  const [selectedClassesPageClass, setSelectedClassesPageClass] = useState("");
  const [selectedClassesPageClassIndex, setSelectedClassesPageClassIndex] =
    useState(9999);

  const [selectedClassesPageDivision, setSelectedClassesPageDivision] =
    useState("");

  const [
    selectedClassesPageDivisionIndex,
    setSelectedClassesPageDivisionIndex,
  ] = useState(9999);

  const [selectedClassesPageSemester, setSelectedClassesPageSemester] =
    useState("");
  const [
    selectedClassesPageSemesterIndex,
    setSelectedClassesPageSemesterIndex,
  ] = useState(9999);

  const [teacherList, setTeacherList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState([]);
  const [finalized, setFinalized] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");

  const [selectedClassesSubjectIndex, setSelectedClassesSubjectIndex] =
    useState(9999);

  const [selectedDay, setSelectedDay] = useState(weekday[new Date().getDay()]);

  const handlePageSelect = (event) => {
    setSelectedPage(event.currentTarget.value);
    if (event.currentTarget.value == "CLASSES") {
      setSelectedClassesPage("CLASSES");
    }
  };

  const [classsPromotePageFrom, setClassPromotePageFrom] = useState("");
  const [divisionPromotePageFrom, setDivisionPromotePageFrom] = useState("");
  const [semesterPromotePageFrom, setSemesterPromotePageFrom] = useState("");

  const [classsPromotePageTo, setClassPromotePageTo] = useState("");
  const [divisionPromotePageTo, setDivisionPromotePageTo] = useState("");
  const [semesterPromotePageTo, setSemesterPromotePageTo] = useState("");
  var colorIndex = 0;

  function getData() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/principal_settings_page_info", body, config)
      .then((res) => {
        setInfo(res.data);
        axios.post(API_URL + "/get_teachers_list", body, config).then((res) => {
          setTeacherList(res.data);
          axios.post(API_URL + "/get_class_list", body, config).then((res) => {
            var classListTemp = res.data;
            setClassList(classListTemp);
            setClassListOld(classListTemp);
            setClass(Object.keys(classListTemp)[0]);
            setDivision(
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            );

            setClassPromotePageFrom(Object.keys(classListTemp)[0]);
            setDivisionPromotePageFrom(
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            );

            setSemesterPromotePageFrom(
              Object.keys(
                classListTemp[Object.keys(classListTemp)[0]][
                  Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
                ]
              )[0]
            );

            setClassPromotePageTo(Object.keys(classListTemp)[0]);
            setDivisionPromotePageTo(
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            );
            setSemesterPromotePageTo(
              Object.keys(
                classListTemp[Object.keys(classListTemp)[0]][
                  Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
                ]
              )[0]
            );

            getTimeTable(
              Object.keys(classListTemp)[0],
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            );

            getStudents(
              Object.keys(classListTemp)[0],
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0],
              Object.keys(
                classListTemp[Object.keys(classListTemp)[0]][
                  Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
                ]
              )[0]
            );

            axios
              .post(API_URL + "/get_fees_structure", body, config)
              .then((res) => {
                setFeesStructure(res.data);
              });
          });
        });
      });
  }

  function addToPromotedStudentsList(value, index) {
    var tempPromotedStudentsList = Array.from(studentList);

    tempPromotedStudentsList[index]["promote"] = value;

    setStudentList(tempPromotedStudentsList);
    setRandom(Math.random());
  }

  function promoteSubmit(unfinalize) {
    setIsSubmitting(true);
    setOpen6(false);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};

    body["institution_id"] = state.auth.user.institution_id;
    body["class_id_next"] = classsPromotePageTo;
    body["division_next"] = divisionPromotePageTo;
    body["semester_next"] = semesterPromotePageTo;
    body["class_id_current"] = classsPromotePageFrom;
    body["division_current"] = divisionPromotePageFrom;
    body["semester_current"] = semesterPromotePageFrom;
    body["student_list"] = studentList;
    body["carry"] = promoteSubmitAnswer;
    body["unfinalize"] = unfinalize;

    body = JSON.stringify(body);
    axios
      .post(API_URL + "/promote_class_division_semester", body, config)
      .then((res) => {
        setIsSubmitting(false);
        setOpen3(false);
        setOpen4(false);
        setOpen5(false);
        setOpen6(false);
        getData();
        createAlert(
          "SUCCESS",
          "Success.",
          "Selected students have been promoted."
        );
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function updateClassList() {
    setIsSubmitting(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};

    var classListTemp = classList;

    for (var i = 0; i < Object.keys(classListTemp).length; i++) {
      if (Object.keys(classListTemp)[i].includes("#edited#")) {
        var newyearname = Object.keys(classListTemp)[i].split("#edited#")[0];
        var newyear = classListTemp[Object.keys(classListTemp)[i]];
        delete classListTemp[Object.keys(classListTemp)[i]];
        classListTemp[newyearname] = newyear;
      }

      for (
        var j = 0;
        j < Object.keys(classListTemp[Object.keys(classListTemp)[i]]).length;
        j++
      ) {
        if (
          Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j].includes(
            "#edited#"
          )
        ) {
          var newdivi =
            classListTemp[Object.keys(classListTemp)[i]][
              Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
            ];
          var newdiviname = Object.keys(
            classListTemp[Object.keys(classListTemp)[i]]
          )[j].split("#edited#")[0];

          delete classListTemp[Object.keys(classListTemp)[i]][
            Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
          ];
          classListTemp[Object.keys(classListTemp)[i]][newdiviname] = newdivi;
        }
        for (
          var k = 0;
          k <
          Object.keys(
            classListTemp[Object.keys(classListTemp)[i]][
              Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
            ]
          ).length;
          k++
        ) {
          if (
            Object.keys(
              classListTemp[Object.keys(classListTemp)[i]][
                Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
              ]
            )[k].includes("#edited#")
          ) {
            var newsem =
              classListTemp[Object.keys(classListTemp)[i]][
                Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
              ][
                Object.keys(
                  classListTemp[Object.keys(classListTemp)[i]][
                    Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
                  ]
                )[k]
              ];
            var newsemname = Object.keys(
              classListTemp[Object.keys(classListTemp)[i]][
                Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
              ]
            )[k].split("#edited#")[0];

            delete classListTemp[Object.keys(classListTemp)[i]][
              Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
            ][
              Object.keys(
                classListTemp[Object.keys(classListTemp)[i]][
                  Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
                ]
              )[k]
            ];
            classListTemp[Object.keys(classListTemp)[i]][
              Object.keys(classListTemp[Object.keys(classListTemp)[i]])[j]
            ][newsemname] = newsem;
          }
        }
      }
    }

    body["institution_id"] = state.auth.user.institution_id;
    body["class_list"] = classListTemp;

    axios.post(API_URL + "/update_class_list", body, config).then((res) => {
      createAlert("SUCCESS", "Success.", "Class settings saved.");
      getData();
      setIsSubmitting(false);
      setOpen2(false);
    });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getData();
    }
  }, []);

  function getTimeTable(cla, div) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};
    body["institution_id"] = state.auth.user.institution_id;
    body["class_id"] = cla;
    body["division"] = div;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/get_student_time_table", body, config)
      .then((res) => {
        setTimeTable(res.data);
        setIsLoading(false);
      });
  }

  function getStudents(cla, div, sem) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["class_id"] = cla;
    body["division"] = div;
    body["current_semester"] = sem;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(
        API_URL + "/get_students_list_class_division_semester",
        body,
        config
      )
      .then((res) => {
        var tempStudentList = res.data;

        for (var i = 0; i < tempStudentList.length; i++) {
          tempStudentList[i]["promote"] = true;
        }

        setStudentList(tempStudentList);
        setRandom(makeid(4));
        setIsReady(true);
        setIsLoading(false);
      });
  }

  function onFileUpload() {
    const formData = new FormData();
    formData.append("file", selectedFile, selectedFile.name);
    var profilPicName =
      state.auth.user.email.replace("@", "").replace(".", "") +
      makeid(5) +
      ".jpg";
    formData.append("email", state.auth.user.email);
    formData.append("user_image", profilPicName); // Details of the uploaded file

    // Request made to the backend api
    // Send formData object

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };
    var time = new Date();

    axios
      .post(API_URL + "/upload_profile_picture", formData, config)
      .then(() => {
        window.location.reload();
      });

    document.getElementById("contained-button-file").value = "";
  }

  function onFileUploadLogo() {
    const formData = new FormData();
    formData.append("file", selectedFileLogo, selectedFileLogo.name);

    formData.append("institution_id", state.auth.user.institution_id);
    formData.append("logo", state.auth.user.institution_id + ".jpg"); // Details of the uploaded file

    // Request made to the backend api
    // Send formData object

    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        // "Content-Type": "application/json",
      },
    };
    var time = new Date();

    axios.post(API_URL + "/upload_logo", formData, config).then(() => {
      window.location.reload();
    });

    document.getElementById("contained-button-file").value = "";
  }

  function onFileChange(event) {
    setSelectedFile(event.target.files[0]);
  }

  function onFileClick(event) {
    document.getElementById("contained-button-file").value = "";
  }

  function onFileChangeLogo(event) {
    setSelectedFileLogo(event.target.files[0]);
  }

  function onFileClickLogo(event) {
    document.getElementById("contained-button-file-logo").value = "";
  }

  function getClassList() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getTeacherList() {
    var teachers = teacherList.map((teacher, index) => (
      <MenuItem value={teacher["email"]}>{teacher["teacher_name"]}</MenuItem>
    ));
    return teachers;
  }

  function handleTeacherChange(teacherEmail, subjectIndex) {
    var tempClassList = classList;
    var tempSubject =
      tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
        selectedClassesPageSemester
      ][subjectIndex];

    for (var i = 0; i < teacherList.length; i++) {
      if (teacherList[i]["email"] == teacherEmail) {
        if (Object.keys(tempSubject)[0] == "edit") {
          tempSubject[Object.keys(tempSubject)[1]]["teacher"] = teacherList[i];
        } else {
          tempSubject[Object.keys(tempSubject)[0]]["teacher"] = teacherList[i];
        }
        break;
      }
    }

    tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      selectedClassesPageSemester
    ][subjectIndex] = tempSubject;

    setClassList(classList);
    setRandom(makeid(5));
  }

  function getClassListPromotePageFrom() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionListPromotePageFrom() {
    var classes = Object.keys(classList[classsPromotePageFrom]).map(
      (division, index) => <MenuItem value={division}>{division}</MenuItem>
    );
    return classes;
  }

  function getSemesterListPromotePageFrom() {
    var semesters = Object.keys(
      classList[classsPromotePageFrom][divisionPromotePageFrom]
    ).map((semester, index) => (
      <MenuItem value={semester}>{semester}</MenuItem>
    ));
    return semesters;
  }

  function getClassListPromotePageTo() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionListPromotePageTo() {
    var classes = Object.keys(classList[classsPromotePageTo]).map(
      (division, index) => <MenuItem value={division}>{division}</MenuItem>
    );
    return classes;
  }

  function getSemesterListPromotePageTo() {
    var semesters = Object.keys(
      classList[classsPromotePageTo][divisionPromotePageTo]
    ).map((semester, index) => (
      <MenuItem value={semester}>{semester}</MenuItem>
    ));
    return semesters;
  }

  function handleSemesterChangePromotePageFrom(e) {
    setSemesterPromotePageFrom(e.target.value);
    getStudents(classsPromotePageFrom, divisionPromotePageFrom, e.target.value);
  }

  function handleClassChangePromotePageFrom(e) {
    setClassPromotePageFrom(e.target.value);
    setIsLoading(true);
    getStudents(
      e.target.value,
      divisionPromotePageFrom,
      semesterPromotePageFrom
    );
  }

  function handleDivisionChangePromotePageFrom(e) {
    setDivisionPromotePageFrom(e.target.value);
    setIsLoading(true);
    getStudents(classsPromotePageFrom, e.target.value, semesterPromotePageFrom);
  }

  function handleSemesterChangePromotePageTo(e) {
    setSemesterPromotePageTo(e.target.value);
  }

  function handleClassChangePromotePageTo(e) {
    setClassPromotePageTo(e.target.value);
  }

  function handleDivisionChangePromotePageTo(e) {
    setDivisionPromotePageTo(e.target.value);
  }

  function getDivisionList() {
    var classes = Object.keys(classList[classs]).map((division, index) => (
      <MenuItem value={division}>{division}</MenuItem>
    ));
    return classes;
  }

  function handleClassChange(e) {
    setClass(e.target.value);

    getTimeTable(e.target.value, division);
  }

  function handleDivisionChange(e) {
    setDivision(e.target.value);
    getTimeTable(classs, e.target.value);
  }
  const didMount = useRef(false);

  const [selectedFile, setSelectedFile] = useState("");
  const [selectedFileLogo, setSelectedFileLogo] = useState("");

  useEffect(() => {
    if (!didMount.current) {
      return (didMount.current = true);
    }
    if (selectedFile) {
      onFileUpload();
    }
    if (selectedFileLogo) {
      onFileUploadLogo();
    }
  }, [selectedFile, selectedFileLogo]);

  function setTimeTableItemSubject(index, subject) {
    var tempTimeTable = timeTable;
    tempTimeTable[selectedDay][index][
      Object.keys(tempTimeTable[selectedDay][index])[0]
    ] = subject;
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function setTimeTableItemClass(index, tt_class) {
    var tempTimeTable = timeTable;
    tempTimeTable[selectedDay][index]["class"] = tt_class;
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function setTimeTableItemTime(index, time) {
    var tempTimeTable = timeTable;
    var tempSubject =
      tempTimeTable[selectedDay][index][
        Object.keys(tempTimeTable[selectedDay][index])[0]
      ];
    delete tempTimeTable[selectedDay][index][
      Object.keys(tempTimeTable[selectedDay][index])[0]
    ];
    tempTimeTable[selectedDay][index][time] = tempSubject;
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function addTimeTableItem() {
    var tempTimeTable = timeTable;
    tempTimeTable[selectedDay].push({ Time: "Subject" });
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function deleteTimeTableItem(index) {
    var tempTimeTable = timeTable;
    tempTimeTable[selectedDay].splice(index, 1);
    setTimeTable(tempTimeTable);
    setRandom(makeid(5));
  }

  function updateTimeTable() {
    setIsSubmitting(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};
    body["institution_id"] = state.auth.user.institution_id;
    body["class_id"] = classs;
    body["division"] = division;
    body["timetable"] = timeTable;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/update_time_table", body, config)
      .then((res) => {
        setIsSubmitting(false);
        setOpen(false);
        createAlert("SUCCESS", "Success.", "Timetable updated.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function updateFeesStructure() {
    setIsSubmitting(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};
    body["institution_id"] = state.auth.user.institution_id;
    body["class_id"] = classs;
    body["division"] = division;
    body["fees_structure"] = feesStructure;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/update_fees_structure", body, config)
      .then((res) => {
        setIsSubmitting(false);
        createAlert("SUCCESS", "Success.", "Fees structure updated.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function deleteSubject(subjectIndex) {
    var tempClassList = classList;

    tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      selectedClassesPageSemester
    ].splice(subjectIndex, 1);

    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function deleteClass(classIndex) {
    var tempClassList = classList;
    delete tempClassList[Object.keys(tempClassList)[classIndex]];

    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function deleteDivision(divisionIndex) {
    var tempClassList = classList;

    delete tempClassList[selectedClassesPageClass][
      Object.keys(tempClassList[selectedClassesPageClass])[divisionIndex]
    ];

    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function deleteSemester(semesterIndex) {
    var tempClassList = classList;

    delete tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      Object.keys(
        tempClassList[selectedClassesPageClass][selectedClassesPageDivision]
      )[semesterIndex]
    ];

    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function handleSubjectNameChange(subjectName, subjectIndex) {
    var tempClassList = classList;
    var tempSubjectValue =
      Object.keys(
        tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
          selectedClassesPageSemester
        ][subjectIndex]
      )[0] == "edit"
        ? Object.values(
            tempClassList[selectedClassesPageClass][
              selectedClassesPageDivision
            ][selectedClassesPageSemester][subjectIndex]
          )[1]
        : Object.values(
            tempClassList[selectedClassesPageClass][
              selectedClassesPageDivision
            ][selectedClassesPageSemester][subjectIndex]
          )[0];

    var tempSubject =
      Object.keys(
        tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
          selectedClassesPageSemester
        ][subjectIndex]
      )[0] == "edit"
        ? Object.keys(
            tempClassList[selectedClassesPageClass][
              selectedClassesPageDivision
            ][selectedClassesPageSemester][subjectIndex]
          )[1]
        : Object.keys(
            tempClassList[selectedClassesPageClass][
              selectedClassesPageDivision
            ][selectedClassesPageSemester][subjectIndex]
          )[0];

    delete tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      selectedClassesPageSemester
    ][subjectIndex][tempSubject];

    tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      selectedClassesPageSemester
    ][subjectIndex][subjectName] = tempSubjectValue;

    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function handleCompulsaryChange(checked, subjectIndex) {
    var tempClassList = classList;
    var tempSubject =
      tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
        selectedClassesPageSemester
      ][subjectIndex];

    console.log(tempSubject);

    if (Object.keys(tempSubject)[0] == "edit") {
      tempSubject[Object.keys(tempSubject)[1]]["compulsary"] = checked;
    } else {
      tempSubject[Object.keys(tempSubject)[0]]["compulsary"] = checked;
    }
    tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      selectedClassesPageSemester
    ][subjectIndex] = tempSubject;

    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  const [tempText, setTempText] = useState("");

  function saveClassName(oldClassName, classIndex) {
    var className = tempText;
    var tempClassList = classList;
    var tempClassValue = tempClassList[Object.keys(classList)[classIndex]];

    if (Object.keys(classList)[classIndex].includes("#edited#")) {
      var oldClassNameTemp = oldClassName.split("#edited#")[1];
      if (oldClassNameTemp == className) {
        tempClassList[className] = tempClassValue;
      } else {
        tempClassList[className + "#edited#" + oldClassNameTemp] =
          tempClassValue;
      }
    } else {
      tempClassList[
        className + "#edited#" + Object.keys(classList)[classIndex]
      ] = tempClassValue;
    }

    delete tempClassList[Object.keys(classList)[classIndex]];
    setSelectedClassesPageClassIndex(Object.keys(tempClassList).length - 1);
    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function saveDivisionName(oldDivisionName, divisionIndex) {
    var divisionName = tempText;
    var tempClassList = classList;
    var tempDivisionValue =
      tempClassList[selectedClassesPageClass][
        Object.keys(classList[selectedClassesPageClass])[divisionIndex]
      ];

    if (
      Object.keys(classList[selectedClassesPageClass])[divisionIndex].includes(
        "#edited#"
      )
    ) {
      var oldDivisionNameTemp = oldDivisionName.split("#edited#")[1];
      if (oldDivisionNameTemp == divisionName) {
        tempClassList[selectedClassesPageClass][divisionName] =
          tempDivisionValue;
      } else {
        tempClassList[selectedClassesPageClass][
          divisionName + "#edited#" + oldDivisionNameTemp
        ] = tempDivisionValue;
      }
    } else {
      tempClassList[selectedClassesPageClass][
        divisionName +
          "#edited#" +
          Object.keys(classList[selectedClassesPageClass])[divisionIndex]
      ] = tempDivisionValue;
    }

    delete tempClassList[selectedClassesPageClass][
      Object.keys(classList[selectedClassesPageClass])[divisionIndex]
    ];
    setSelectedClassesPageDivisionIndex(
      Object.keys(tempClassList[selectedClassesPageClass]).length - 1
    );
    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function saveSemesterName(oldSemesterName, semesterIndex) {
    var semesterName = tempText;
    var tempClassList = classList;
    var tempSemesterValue =
      tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
        Object.keys(
          classList[selectedClassesPageClass][selectedClassesPageDivision]
        )[semesterIndex]
      ];

    if (
      Object.keys(
        classList[selectedClassesPageClass][selectedClassesPageDivision]
      )[semesterIndex].includes("#edited#")
    ) {
      var oldSemesterNameTemp = oldSemesterName.split("#edited#")[1];
      if (oldSemesterNameTemp == semesterName) {
        tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
          semesterName
        ] = tempSemesterValue;
      } else {
        tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
          semesterName + "#edited#" + oldSemesterNameTemp
        ] = tempSemesterValue;
      }
    } else {
      tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
        semesterName +
          "#edited#" +
          Object.keys(
            classList[selectedClassesPageClass][selectedClassesPageDivision]
          )[semesterIndex]
      ] = tempSemesterValue;
    }

    delete tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      Object.keys(
        classList[selectedClassesPageClass][selectedClassesPageDivision]
      )[semesterIndex]
    ];
    setSelectedClassesPageSemesterIndex(
      Object.keys(
        tempClassList[selectedClassesPageClass][selectedClassesPageDivision]
      ).length - 1
    );
    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function addNewSubject() {
    var tempClassList = classList;
    tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      selectedClassesPageSemester
    ].push({
      "New Subject": {
        compulsary: false,
        teacher: { email: "" },
      },
    });
    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function addNewClass() {
    var tempClassList = classList;
    tempClassList["New Class"] = {
      "New Division": {
        "New Semester": [
          { "New Subject": { compulsary: false, teacher: { email: "" } } },
        ],
      },
    };
    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function addNewDivision() {
    var tempClassList = classList;
    tempClassList[selectedClassesPageClass] = {
      "New Division": {
        "New Semester": [
          { "New Subject": { compulsary: false, teacher: { email: "" } } },
        ],
      },
    };
    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function addNewSemester() {
    var tempClassList = classList;
    tempClassList[selectedClassesPageClass][selectedClassesPageDivision][
      "New Semester"
    ] = [{ "New Subject": { compulsary: false, teacher: { email: "" } } }];
    setClassList(tempClassList);
    setRandom(makeid(5));
  }

  function addFeesExpense() {
    var tempFeesStructure = feesStructure;
    tempFeesStructure[classs][division]["New expense"] = "10000";
    setFeesStructure(tempFeesStructure);
    setRandom(makeid(5));
  }

  function deleteFeesExpense(index) {
    var tempFeesStructure = feesStructure;
    delete tempFeesStructure[classs][division][
      Object.keys(tempFeesStructure[classs][division])[index]
    ];
    setFeesStructure(tempFeesStructure);
    setRandom(makeid(5));
  }

  function editFeesExpense(index, value) {
    var tempFeesStructure = feesStructure;
    var tempFeesCost =
      tempFeesStructure[classs][division][
        Object.keys(tempFeesStructure[classs][division])[index]
      ];
    delete tempFeesStructure[classs][division][
      Object.keys(tempFeesStructure[classs][division])[index]
    ];
    tempFeesStructure[classs][division][value] = tempFeesCost;
    setFeesStructure(tempFeesStructure);
    setRandom(makeid(5));
  }

  function editFeesCost(index, value) {
    var tempFeesStructure = feesStructure;
    tempFeesStructure[classs][division][
      Object.keys(tempFeesStructure[classs][division])[index]
    ] = value;
    setFeesStructure(tempFeesStructure);
    setRandom(makeid(5));
  }

  function getTotalFees() {
    var total = 0;
    for (
      var i = 0;
      i < Object.keys(feesStructure[classs][division]).length;
      i++
    ) {
      total += parseInt(
        feesStructure[classs][division][
          Object.keys(feesStructure[classs][division])[i]
        ].replaceAll(",", "")
      );
    }
    return total;
  }

  function changePhoneNumber() {
    if (
      phoneNumber.length == 0 ||
      (phoneNumber.length > 15 && /\D/.test(phoneNumber))
    ) {
      createAlert("ERROR", "Error", "Enter valid phone number.");
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };

      body["email"] = state.auth.user.email;
      body["phone_number"] = phoneNumber;
      body = JSON.stringify(body);
      axios.post(API_URL + "/change_phone_number", body, config).then((res) => {
        createAlert("SUCCESS", "Success", "Phone Number updated.");
        window.location.reload();
      });
    }
  }

  function selectedPageContent() {
    if (selectedPage == "INSTITUTION") {
      return (
        <Grid container spacing={10}>
          <Grid item md={6} style={{ marginTop: "-15px" }}>
            <h2>Personal Details</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>
                Name :
                <b>
                  {" " +
                    state.auth.user.firstname +
                    " " +
                    state.auth.user.lastname}
                </b>
                <br></br>
                Institution ID : {" " + state.auth.user.institution_id}
              </span>
              <div
                style={{
                  display: "flex",
                }}
              >
                <input
                  id="contained-button-file"
                  style={{
                    display: "none",
                    height: "150px",
                    cursor: "pointer",
                    width: "150px",
                  }}
                  type="file"
                  onChange={(e) => onFileChange(e)}
                  onClick={(e) => onFileClick(e)}
                />
                <label
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  htmlFor="contained-button-file"
                >
                  <Avatar
                    style={{
                      height: "150px",
                      width: "150px",
                      marginTop: "-50px",
                      cursor: "pointer",
                    }}
                    alt={
                      state.auth.user.firstname + " " + state.auth.user.lastname
                    }
                    src={API_URL + "/media/" + state.auth.user.user_image}
                  />
                  <h5 style={{ textAlign: "center", marginTop: "5px" }}>
                    UPLOAD PHOTO
                  </h5>
                </label>
                <input
                  id="contained-button-file-logo"
                  style={{
                    display: "none",
                    height: "150px",
                    cursor: "pointer",
                    width: "150px",
                    marginLeft: "30px",
                  }}
                  type="file"
                  onChange={(e) => onFileChangeLogo(e)}
                  onClick={(e) => onFileClickLogo(e)}
                />
                <label
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  htmlFor="contained-button-file-logo"
                >
                  <img
                    style={{
                      height: "150px",
                      width: "150px",
                      marginTop: "-50px",
                      cursor: "pointer",
                      marginLeft: "30px",
                    }}
                    alt={state.auth.user.institution_id}
                    src={
                      API_URL +
                      "/media/" +
                      state.auth.user.institution_id +
                      ".jpg"
                    }
                  />
                  <h5
                    style={{
                      textAlign: "center",
                      marginTop: "5px",
                      marginLeft: "30px",
                    }}
                  >
                    UPLOAD LOGO
                  </h5>
                </label>
              </div>
            </div>
            <br></br>
            <br></br>
            <span>
              Email : <b>{state.auth.user.email}</b>
            </span>{" "}
            <br></br>
            <span>
              Phone Number : <b>{state.auth.user.phone_number}</b>
            </span>
            <br></br>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <TextField
                style={{ marginTop: "10px" }}
                fullWidth
                size="small"
                placeholder="Phone Number"
                variant="outlined"
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Button
                style={{
                  fontSize: "15px",
                  height: "40px",
                  marginTop: "10px",
                  marginLeft: "10px",
                }}
                onClick={() => {
                  changePhoneNumber();
                }}
                variant="contained"
                color="primary"
              >
                <h5>Change</h5>
              </Button>
            </div>
          </Grid>
          <Grid item md={6}>
            <b style={{ fontSize: "20px" }}>Student Count:</b>
            <div style={{ marginTop: "-20px" }}>
              <h1>{info["student_count"]}</h1>
            </div>
            <b style={{ fontSize: "20px" }}>Teacher Count:</b>
            <div style={{ marginTop: "-20px" }}>
              <h1>{info["teacher_count"]}</h1>
            </div>
          </Grid>
        </Grid>
      );
    } else if (selectedPage == "TIMETABLE") {
      return (
        <div style={{ marginTop: "-50px" }}>
          <Grid container spacing={3}>
            <Grid item xs={8}>
              <h2>
                Timetable for
                <span style={{ color: "#3182bd" }}> {selectedDay}</span>
              </h2>

              {timeTable[selectedDay] == "" ? (
                <Box
                  height={60}
                  borderRadius="6px"
                  style={{
                    backgroundColor: colorList[colorIndex],
                    borderBottom: "2px solid " + colorList2[colorIndex],
                    width: "100%",
                    padding: "2px",
                  }}
                >
                  <div
                    style={{
                      margin: "2px 0px 0px 12px",
                      color: colorList2[colorIndex],
                      fontSize: "20px",
                    }}
                  >
                    No lectures today!
                  </div>
                  <div
                    style={{
                      margin: "-3px 0px 0px 12px",
                      color: colorList2[colorIndex],
                      fontSize: "15px",
                    }}
                  >
                    Press The " + " button to add lectures.
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30px",
                    }}
                  >
                    <Fab
                      size="small"
                      style={{
                        boxShadow: "none",
                        backgroundColor: "#3182bd",
                      }}
                      onClick={() => addTimeTableItem(selectedDay)}
                      aria-label="add"
                    >
                      <AddIcon
                        style={{
                          color: "white",
                        }}
                      />
                    </Fab>
                  </div>
                </Box>
              ) : (
                <Fragment>
                  {timeTable[selectedDay].map((lecture, index) => (
                    <Fragment>
                      <Grid
                        container
                        spacing={0}
                        justifyContent="flex-start"
                        alignItems="center"
                      >
                        <Grid md={1}>
                          {itemIndex == index ? (
                            <TextField
                              style={{
                                marginTop: "2px",
                                marginLeft: "-5px",
                                width: "75px",
                              }}
                              InputProps={{
                                textAlign: "center",
                                disableUnderline: true,
                                style: {
                                  color: "#3182bd",
                                  textAlign: "center",
                                },
                              }}
                              value={Object.keys(lecture)[0]}
                              fullWidth
                              size="small"
                              onChange={(e) =>
                                setTimeTableItemTime(
                                  index,
                                  e.target.value,
                                  lecture["class"]
                                )
                              }
                              placeholder={"Time"}
                              variant="outlined"
                            />
                          ) : (
                            <p
                              style={{
                                fontSize: "15px",
                                marginTop: "12px",
                                marginLeft: "10px",
                              }}
                            >
                              {Object.keys(lecture)[0]}
                              <span style={{ display: "none" }}>
                                {
                                  (colorIndex = Math.floor(
                                    Math.random() * colorList.length
                                  ))
                                }
                              </span>
                            </p>
                          )}
                        </Grid>
                        <Grid item md={8}>
                          <Box
                            borderRadius="10px"
                            style={
                              Object.values(lecture)[0]
                                .toLowerCase()
                                .includes("break") ||
                              Object.values(lecture)[0]
                                .toLowerCase()
                                .includes("lunch")
                                ? {
                                    border: "2px solid grey",
                                    padding: "2px",
                                    display: "flex",
                                    height:
                                      itemIndex == index ? "100%" : "55px",
                                  }
                                : {
                                    padding: "2px",
                                    display: "flex",
                                    padding: "2px",
                                    height:
                                      itemIndex == index ? "100%" : "55px",
                                    backgroundColor: colorList[colorIndex],
                                  }
                            }
                          >
                            <div
                              style={{
                                margin:
                                  Object.values(lecture)[0]
                                    .toLowerCase()
                                    .includes("break") ||
                                  Object.values(lecture)[0]
                                    .toLowerCase()
                                    .includes("lunch")
                                    ? "0px 0px 0px 10px"
                                    : "2px 0px 0px 12px",
                                color: colorList2[colorIndex],
                              }}
                            >
                              {getIcon(Object.values(lecture)[0])}
                            </div>
                            <div>
                              <div>
                                {itemIndex == index ? (
                                  <TextField
                                    style={{
                                      marginTop: "5px",
                                      marginLeft: "10px",
                                      backgroundColor: "white",
                                    }}
                                    InputProps={{
                                      textAlign: "center",
                                      disableUnderline: true,
                                      style: {
                                        color: "#3182bd",
                                        textAlign: "center",
                                      },
                                    }}
                                    value={Object.values(lecture)[0]}
                                    fullWidth
                                    size="small"
                                    onChange={(e) =>
                                      setTimeTableItemSubject(
                                        index,
                                        e.target.value
                                      )
                                    }
                                    placeholder={"Subject"}
                                    variant="outlined"
                                  />
                                ) : (
                                  <Fragment>
                                    <div
                                      style={{
                                        margin:
                                          Object.values(lecture)[0]
                                            .toLowerCase()
                                            .includes("break") ||
                                          Object.values(lecture)[0]
                                            .toLowerCase()
                                            .includes("lunch")
                                            ? "-3px 0px 0px 12px"
                                            : "0px 0px 0px 12px",
                                        color:
                                          Object.values(lecture)[0]
                                            .toLowerCase()
                                            .includes("break") ||
                                          Object.values(lecture)[0]
                                            .toLowerCase()
                                            .includes("lunch")
                                            ? "black"
                                            : colorList2[colorIndex],
                                        fontSize: "20px",
                                      }}
                                    >
                                      {Object.values(lecture)[0]}
                                    </div>
                                  </Fragment>
                                )}
                              </div>
                              <br></br>
                              {itemIndex == index ? (
                                <TextField
                                  style={{
                                    marginLeft: "10px",
                                    marginTop: "-17px",
                                    marginBottom: "5px",
                                    backgroundColor: "white",
                                  }}
                                  InputProps={{
                                    textAlign: "center",
                                    disableUnderline: true,
                                    style: {
                                      color: "#3182bd",
                                      textAlign: "center",
                                    },
                                  }}
                                  value={lecture["class"]}
                                  fullWidth
                                  size="small"
                                  onChange={(e) =>
                                    setTimeTableItemClass(index, e.target.value)
                                  }
                                  placeholder={"Teacher, Classroom"}
                                  variant="outlined"
                                />
                              ) : (
                                <div
                                  style={{
                                    margin: "-28px 0px 0px 12px",
                                    color:
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("break") ||
                                      Object.values(lecture)[0]
                                        .toLowerCase()
                                        .includes("lunch")
                                        ? "black"
                                        : colorList2[colorIndex],
                                    fontSize: "15px",
                                  }}
                                >
                                  {lecture["class"]}
                                </div>
                              )}
                            </div>
                          </Box>
                        </Grid>
                        <Grid md={3}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {itemIndex == index ? (
                              <Button
                                onClick={() => setItemIndex(9999)}
                                style={{ borderRadius: "20px" }}
                              >
                                <SaveIcon />
                              </Button>
                            ) : (
                              <Button
                                onClick={() => setItemIndex(index)}
                                style={{ borderRadius: "20px" }}
                              >
                                <EditIcon />
                              </Button>
                            )}

                            <Button
                              onClick={() => deleteTimeTableItem(index)}
                              style={{ borderRadius: "20px" }}
                            >
                              <DeleteIcon style={{ color: "#ff3333" }} />
                            </Button>
                          </div>
                        </Grid>
                      </Grid>
                      <br></br>
                    </Fragment>
                  ))}

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      style={{
                        backgroundColor: "#3283c9",
                        borderRadius: 5,
                        justifyContent: "flex-start",
                        height: "35px",
                        width: "150px",
                      }}
                      onClick={() => addTimeTableItem(selectedDay)}
                    >
                      <AddIcon
                        name="add"
                        style={{ color: "white", fontSize: "30px" }}
                      />
                      <span
                        style={{
                          textAlign: "center",
                          color: "white",
                          marginTop: "1px",
                          marginLeft: "8px",
                          fontFamily: "Bahnschrift",
                          fontSize: "16px",
                        }}
                      >
                        ADD ITEM
                      </span>
                    </Button>
                  </div>
                </Fragment>
              )}
              <br></br>
            </Grid>
            <Grid item md={4} style={{ marginTop: "20px" }}>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Class
              </span>
              <Select
                value={classs}
                onChange={(e) => handleClassChange(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getClassList()}
              </Select>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Division
              </span>
              <Select
                value={division}
                onChange={(e) => handleDivisionChange(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getDivisionList()}
              </Select>

              <Box
                borderRadius="6px"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                  border: "2px solid#d7ecff",
                  padding: "10px",
                  marginTop: "10px",
                  alignItems: "center",
                }}
              >
                <Grid container spacing={1.5}>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        backgroundColor:
                          selectedDay == "Monday" ? "#3182bd" : "white",
                        boxShadow: "none",
                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Monday" ? "bold" : "lighter",
                        color: selectedDay == "Monday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Monday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Monday" ? "contained" : ""}
                    >
                      Monday
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        boxShadow: "none",
                        backgroundColor:
                          selectedDay == "Tuesday" ? "#3182bd" : "white",

                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Tuesday" ? "bold" : "lighter",
                        color: selectedDay == "Tuesday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Tuesday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Tuesday" ? "contained" : ""}
                    >
                      Tuesday
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        boxShadow: "none",
                        backgroundColor:
                          selectedDay == "Wednesday" ? "#3182bd" : "white",
                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Wednesday" ? "bold" : "lighter",
                        color: selectedDay == "Wednesday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Wednesday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Wednesday" ? "contained" : ""}
                    >
                      Wednesday
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        backgroundColor:
                          selectedDay == "Thursday" ? "#3182bd" : "white",
                        boxShadow: "none",
                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Thursday" ? "bold" : "lighter",
                        color: selectedDay == "Thursday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Thursday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Thursday" ? "contained" : ""}
                    >
                      Thursday
                    </Button>
                  </Grid>{" "}
                  <Grid item md={4}>
                    {" "}
                    <Button
                      style={{
                        height: "30px",
                        boxShadow: "none",
                        backgroundColor:
                          selectedDay == "Friday" ? "#3182bd" : "white",
                        width: "100%",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Friday" ? "bold" : "lighter",
                        color: selectedDay == "Friday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Friday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Friday" ? "contained" : ""}
                    >
                      Friday
                    </Button>
                  </Grid>
                  <Grid item md={4}>
                    <Button
                      style={{
                        height: "30px",
                        boxShadow: "none",
                        width: "100%",
                        backgroundColor:
                          selectedDay == "Saturday" ? "#3182bd" : "white",
                        fontFamily: "Lato-Regular",
                        borderRadius: "3px",
                        fontWeight:
                          selectedDay == "Saturday" ? "bold" : "lighter",
                        color: selectedDay == "Saturday" ? "white" : "#3182bd",
                      }}
                      onClick={() => {
                        setSelectedDay("Saturday");
                        setItemIndex(9999);
                      }}
                      variant={selectedDay == "Saturday" ? "contained" : ""}
                    >
                      Saturday
                    </Button>
                  </Grid>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "10px",
                    width: "34%",
                  }}
                >
                  <Button
                    style={{
                      height: "30px",
                      boxShadow: "none",
                      width: "90%",
                      backgroundColor:
                        selectedDay == "Sunday" ? "#3182bd" : "white",
                      fontFamily: "Lato-Regular",
                      borderRadius: "3px",
                      marginLeft: "6px",
                      fontWeight: selectedDay == "Sunday" ? "bold" : "lighter",
                      color: selectedDay == "Sunday" ? "white" : "#3182bd",
                    }}
                    onClick={() => {
                      setSelectedDay("Sunday");
                      setItemIndex(9999);
                    }}
                    variant={selectedDay == "Sunday" ? "contained" : ""}
                  >
                    Sunday
                  </Button>
                </div>
              </Box>

              <Button
                onClick={() => updateTimeTable()}
                variant="contained"
                style={{
                  width: "100%",
                  marginTop: "15px",
                  height: "35px",
                  backgroundColor: "#3182bd",
                }}
              >
                <h3>Update Timetable</h3>
              </Button>
              {isSubmitting ? (
                <div
                  style={{
                    marginTop: "15px",
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
        </div>
      );
    } else if (selectedPage == "PROMOTE") {
      return (
        <Fragment>
          <Grid container spacing={3}>
            <Grid item md={4}>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Current Class
              </span>
              <Select
                value={classsPromotePageFrom}
                onChange={(e) => handleClassChangePromotePageFrom(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getClassListPromotePageFrom()}
              </Select>
            </Grid>
            <Grid item md={4}>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Current Division
              </span>
              <Select
                value={divisionPromotePageFrom}
                onChange={(e) => handleDivisionChangePromotePageFrom(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getDivisionListPromotePageFrom()}
              </Select>
            </Grid>

            <Grid item md={4}>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Current Semester
              </span>
              <Select
                value={semesterPromotePageFrom}
                onChange={(e) => handleSemesterChangePromotePageFrom(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getSemesterListPromotePageFrom()}
              </Select>
            </Grid>
          </Grid>
          <Grid style={{ marginTop: "15px" }} container spacing={3}>
            {studentList.map((student, index) => (
              <Grid item xs={3}>
                <CardActionArea
                  sx={[
                    {
                      borderRadius: "6px",
                      border: "1.5px solid #d7ecff",
                      transition: "background-color 0.5s, border-color 0.5s",
                    },
                    (theme) => ({
                      "&:hover": {
                        backgroundColor: "#ebf5ff",
                        borderColor: "#3182bd",
                      },
                      ".MuiCardActionArea-focusHighlight": {
                        background: "transparent",
                      },

                      "&& .MuiTouchRipple-child": {
                        backgroundColor: "#084594",
                      },
                    }),
                  ]}
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    marginBottom: "5px",
                    border: "2px solid #d7ecff",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => {}}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item md={10}>
                      <b
                        style={{
                          fontSize: "20px",
                          fontFamily: "Lato-Bold",
                          color: "#084594",
                        }}
                      >
                        {student["name"]}
                      </b>
                      <br></br>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "16px",
                        }}
                      >
                        {" "}
                        Roll no
                        <span style={{ fontFamily: "Lato-Bold" }}>
                          {" " + student["roll_number"]}
                        </span>
                      </span>
                    </Grid>

                    <Grid item md={1}>
                      <Checkbox
                        checked={studentList[index]["promote"]}
                        onChange={(e) => {
                          addToPromotedStudentsList(e.target.checked, index);
                        }}
                        value={1}
                        color={"primary"}
                        style={{}}
                      />
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
          <Grid style={{ marginTop: "15px" }} container spacing={3}>
            <Grid item md={4}>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Pomote to Class
              </span>
              <Select
                value={classsPromotePageTo}
                onChange={(e) => handleClassChangePromotePageTo(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getClassListPromotePageTo()}
              </Select>
            </Grid>
            <Grid item md={4}>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Promote to Division
              </span>
              <Select
                value={divisionPromotePageTo}
                onChange={(e) => handleDivisionChangePromotePageTo(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getDivisionListPromotePageTo()}
              </Select>
            </Grid>
            <Grid item md={4}>
              <span
                style={{
                  color: "#3182bd",
                  fontFamily: "Lato-Regular",
                  fontSize: "14px",
                }}
              >
                Promote to Semester
              </span>
              <Select
                value={semesterPromotePageTo}
                onChange={(e) => handleSemesterChangePromotePageTo(e)}
                style={{
                  marginTop: "2px",
                  height: "40px",
                  marginBottom: "5px",
                }}
                fullWidth
                variant={"outlined"}
              >
                {getSemesterListPromotePageTo()}
              </Select>
            </Grid>
          </Grid>
        </Fragment>
      );
    } else if (selectedPage == "CLASSES") {
      if (selectedClassesPage == "CLASSES") {
        return (
          <Fragment>
            <Grid container spacing={3}>
              {Object.keys(classList).map((classListClass, classIndex) => (
                <Grid item md={3}>
                  {classIndex == selectedClassesPageClassIndex ? (
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={true}
                      onClose={() => setOpen(false)}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={true}>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            backgroundColor: "white",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            padding: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <TextField
                              InputProps={{
                                textAlign: "center",
                                disableUnderline: true,
                                style: {
                                  color: "#3182bd",
                                  textAlign: "center",
                                },
                              }}
                              value={tempText.split("#edited#")[0]}
                              fullWidth
                              size="small"
                              onChange={(e) => setTempText(e.target.value)}
                              placeholder={"Name"}
                              variant="outlined"
                            />

                            <CardActionArea
                              style={{
                                borderRadius: "20px",
                                width: "30px",
                                height: "30px",
                                marginLeft: "20px",
                                marginTop: "5px",
                              }}
                              onClick={() => {
                                saveClassName(classListClass, classIndex);
                                setSelectedClassesPageClassIndex(9999);
                              }}
                            >
                              <CheckIcon style={{ color: "#3283c9" }} />
                            </CardActionArea>
                          </div>
                        </div>
                      </Fade>
                    </Modal>
                  ) : (
                    <Card
                      style={{
                        minHeight: "100%",
                        backgroundColor: "white",
                        boxShadow: "none",
                        padding: "8px 12px 10px 12px",
                      }}
                    >
                      <CardActionArea
                        sx={[
                          {
                            borderRadius: "6px",
                            border: "1.5px solid #d7ecff",
                            transition:
                              "background-color 0.5s, border-color 0.5s",
                          },
                          (theme) => ({
                            "&:hover": {
                              backgroundColor: "#ebf5ff",
                              borderColor: "#3182bd",
                            },
                            ".MuiCardActionArea-focusHighlight": {
                              background: "transparent",
                            },

                            "&& .MuiTouchRipple-child": {
                              backgroundColor: "#084594",
                            },
                          }),
                        ]}
                        style={{
                          padding: "10px",
                        }}
                        onClick={(e) => {
                          setSelectedClassesPageClass(classListClass);
                          setSelectedClassesPageClassIndex(9999);
                          setSelectedClassesPage("DIVISIONS");
                        }}
                      >
                        <div
                          style={{ marginTop: "-22px", marginBottom: "-20px" }}
                        >
                          <h2>{classListClass.split("#edited#")[0]}</h2>
                        </div>
                      </CardActionArea>
                      {!state.auth.finalized ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "15px",
                          }}
                        >
                          <CardActionArea
                            sx={{
                              "&:hover": {
                                backgroundColor: "#f1f8ff",
                                transition: "background-color 0.5s",
                              },
                            }}
                            style={{
                              borderRadius: "20px",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setTempText(classListClass);
                              setSelectedClassesPageClassIndex(classIndex);
                            }}
                          >
                            <EditIcon style={{ color: "#3283c9" }} />
                          </CardActionArea>

                          <CardActionArea
                            sx={{
                              "&:hover": {
                                backgroundColor: "#f1f8ff",
                                transition: "background-color 0.5s",
                              },
                            }}
                            style={{
                              borderRadius: "20px",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              deleteClass(classIndex);
                            }}
                          >
                            <DeleteIcon style={{ color: "#ff3333" }} />
                          </CardActionArea>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
            {state.auth.finalized ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#3283c9",
                    borderRadius: 5,
                    justifyContent: "flex-start",
                    height: "35px",
                    width: "200px",
                  }}
                  fullWidth
                  onClick={() => addNewClass()}
                >
                  <AddIcon
                    name="add"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                  <span
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "1px",
                      marginLeft: "24px",
                      fontFamily: "Bahnschrift",

                      fontSize: "16px",
                    }}
                  >
                    ADD CLASS
                  </span>
                </Button>
              </div>
            )}
          </Fragment>
        );
      } else if (selectedClassesPage == "DIVISIONS") {
        return (
          <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#3182bd",
                  color: "white",
                  width: "5%",
                  height: "35px",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                onClick={() => setSelectedClassesPage("CLASSES")}
              >
                <ArrowBackIcon style={{ fontSize: "25px" }} />
              </Button>
              <div
                textAlign="center"
                style={{
                  color: "#084594",
                  backgroundColor: "#d7ecff",
                  width: "95%",
                  height: "35px",
                  padding: "3px 0px 0px 25px",
                  alignItems: "center",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "-10px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>
                    {selectedClassesPageClass.split("#edited#")[0]} Divisions
                  </h2>
                </div>
              </div>
            </div>

            <Grid container spacing={2}>
              {Object.keys(classList[selectedClassesPageClass]).map(
                (classListDivision, divisionIndex) => (
                  <Grid item md={3}>
                    {divisionIndex == selectedClassesPageDivisionIndex ? (
                      <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        open={true}
                        onClose={() => setOpen(false)}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                          timeout: 500,
                        }}
                      >
                        <Fade in={true}>
                          <div
                            style={{
                              position: "absolute",
                              top: "50%",
                              backgroundColor: "white",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              width: 400,
                              padding: "20px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <TextField
                                InputProps={{
                                  textAlign: "center",
                                  disableUnderline: true,
                                  style: {
                                    color: "#3182bd",
                                    textAlign: "center",
                                  },
                                }}
                                value={tempText.split("#edited#")[0]}
                                fullWidth
                                size="small"
                                onChange={(e) => setTempText(e.target.value)}
                                placeholder={"Name"}
                                variant="outlined"
                              />

                              <CardActionArea
                                style={{
                                  borderRadius: "20px",
                                  width: "30px",
                                  height: "30px",
                                  marginLeft: "20px",
                                  marginTop: "5px",
                                }}
                                onClick={() => {
                                  saveDivisionName(
                                    classListDivision,
                                    divisionIndex
                                  );
                                  setSelectedClassesPageDivisionIndex(9999);
                                }}
                              >
                                <CheckIcon style={{ color: "#3283c9" }} />
                              </CardActionArea>
                            </div>
                          </div>
                        </Fade>
                      </Modal>
                    ) : (
                      <Card
                        style={{
                          minHeight: "100%",
                          backgroundColor: "white",
                          boxShadow: "none",
                          padding: "8px 12px 10px 12px",
                        }}
                      >
                        <CardActionArea
                          sx={[
                            {
                              borderRadius: "6px",
                              border: "1.5px solid #d7ecff",
                              transition:
                                "background-color 0.5s, border-color 0.5s",
                            },
                            (theme) => ({
                              "&:hover": {
                                backgroundColor: "#ebf5ff",
                                borderColor: "#3182bd",
                              },
                              ".MuiCardActionArea-focusHighlight": {
                                background: "transparent",
                              },

                              "&& .MuiTouchRipple-child": {
                                backgroundColor: "#084594",
                              },
                            }),
                          ]}
                          style={{
                            padding: "10px",
                          }}
                          onClick={(e) => {
                            setSelectedClassesPageDivision(classListDivision);
                            setSelectedClassesPageDivisionIndex(9999);
                            setSelectedClassesPage("SEMESTERS");
                          }}
                        >
                          <div
                            style={{
                              marginTop: "-22px",
                              marginBottom: "-20px",
                            }}
                          >
                            <h2>{classListDivision.split("#edited#")[0]}</h2>
                          </div>
                        </CardActionArea>
                        {!state.auth.finalized ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "flex-end",
                              marginTop: "15px",
                            }}
                          >
                            <CardActionArea
                              style={{
                                borderRadius: "20px",
                                width: "30px",
                                height: "30px",
                              }}
                              onClick={() => {
                                setTempText(classListDivision);
                                setSelectedClassesPageDivisionIndex(
                                  divisionIndex
                                );
                              }}
                            >
                              <EditIcon style={{ color: "#3283c9" }} />
                            </CardActionArea>

                            <CardActionArea
                              style={{
                                borderRadius: "20px",
                                width: "30px",
                                height: "30px",
                              }}
                              onClick={() => {
                                deleteDivision(divisionIndex);
                              }}
                            >
                              <DeleteIcon style={{ color: "#ff3333" }} />
                            </CardActionArea>
                          </div>
                        ) : (
                          ""
                        )}
                      </Card>
                    )}
                  </Grid>
                )
              )}
            </Grid>
            {state.auth.finalized ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#3283c9",
                    borderRadius: 5,
                    justifyContent: "flex-start",
                    height: "35px",
                    width: "200px",
                  }}
                  fullWidth
                  onClick={() => addNewDivision()}
                >
                  <AddIcon
                    name="add"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                  <span
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "1px",
                      marginLeft: "20px",
                      fontFamily: "Bahnschrift",

                      fontSize: "16px",
                    }}
                  >
                    ADD DIVISION
                  </span>
                </Button>
              </div>
            )}
          </Fragment>
        );
      } else if (selectedClassesPage == "SEMESTERS") {
        return (
          <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#3182bd",
                  color: "white",
                  width: "5%",
                  height: "35px",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                onClick={() => setSelectedClassesPage("DIVISIONS")}
              >
                <ArrowBackIcon style={{ fontSize: "25px" }} />
              </Button>
              <div
                textAlign="center"
                style={{
                  color: "#084594",
                  backgroundColor: "#d7ecff",
                  width: "95%",
                  height: "35px",
                  padding: "3px 0px 0px 25px",
                  alignItems: "center",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "-10px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>
                    {selectedClassesPageClass.split("#edited#")[0] +
                      " - " +
                      selectedClassesPageDivision.split("#edited#")[0] +
                      " - "}
                    Semesters
                  </h2>
                </div>
              </div>
            </div>

            <Grid container spacing={2}>
              {Object.keys(
                classList[selectedClassesPageClass][selectedClassesPageDivision]
              ).map((classListSemester, semesterIndex) => (
                <Grid item md={3}>
                  {semesterIndex == selectedClassesPageSemesterIndex ? (
                    <Modal
                      aria-labelledby="transition-modal-title"
                      aria-describedby="transition-modal-description"
                      open={true}
                      onClose={() => setOpen(false)}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                    >
                      <Fade in={true}>
                        <div
                          style={{
                            position: "absolute",
                            top: "50%",
                            backgroundColor: "white",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            padding: "20px",
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                            }}
                          >
                            <TextField
                              InputProps={{
                                textAlign: "center",
                                disableUnderline: true,
                                style: {
                                  color: "#3182bd",
                                  textAlign: "center",
                                },
                              }}
                              value={tempText.split("#edited#")[0]}
                              fullWidth
                              size="small"
                              onChange={(e) => setTempText(e.target.value)}
                              placeholder={"Name"}
                              variant="outlined"
                            />

                            <CardActionArea
                              style={{
                                borderRadius: "20px",
                                width: "30px",
                                height: "30px",
                                marginLeft: "20px",
                                marginTop: "5px",
                              }}
                              onClick={() => {
                                saveSemesterName(
                                  classListSemester,
                                  semesterIndex
                                );
                                setSelectedClassesPageSemesterIndex(9999);
                              }}
                            >
                              <CheckIcon style={{ color: "#3283c9" }} />
                            </CardActionArea>
                          </div>
                        </div>
                      </Fade>
                    </Modal>
                  ) : (
                    <Card
                      style={{
                        minHeight: "100%",
                        backgroundColor: "white",
                        boxShadow: "none",
                        padding: "8px 12px 10px 12px",
                      }}
                    >
                      <CardActionArea
                        sx={[
                          {
                            borderRadius: "6px",
                            border: "1.5px solid #d7ecff",
                            transition:
                              "background-color 0.5s, border-color 0.5s",
                          },
                          (theme) => ({
                            "&:hover": {
                              backgroundColor: "#ebf5ff",
                              borderColor: "#3182bd",
                            },
                            ".MuiCardActionArea-focusHighlight": {
                              background: "transparent",
                            },

                            "&& .MuiTouchRipple-child": {
                              backgroundColor: "#084594",
                            },
                          }),
                        ]}
                        style={{
                          padding: "10px",
                        }}
                        onClick={(e) => {
                          setSelectedClassesPageSemester(classListSemester);
                          setSelectedClassesPageSemesterIndex(9999);
                          setSelectedClassesPage("SUBJECTS");
                        }}
                      >
                        <div
                          style={{
                            marginTop: "-22px",
                            marginBottom: "-20px",
                          }}
                        >
                          <h2>{classListSemester.split("#edited#")[0]}</h2>
                        </div>
                      </CardActionArea>
                      {!state.auth.finalized ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "15px",
                          }}
                        >
                          <CardActionArea
                            sx={{
                              "&:hover": {
                                backgroundColor: "#f1f8ff",
                                transition: "background-color 0.5s",
                              },
                            }}
                            style={{
                              borderRadius: "20px",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              setTempText(classListSemester);
                              setSelectedClassesPageSemesterIndex(
                                semesterIndex
                              );
                            }}
                          >
                            <EditIcon style={{ color: "#3283c9" }} />
                          </CardActionArea>

                          <CardActionArea
                            sx={{
                              "&:hover": {
                                backgroundColor: "#f1f8ff",
                                transition: "background-color 0.5s",
                              },
                            }}
                            style={{
                              borderRadius: "20px",
                              width: "30px",
                              height: "30px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            onClick={() => {
                              deleteSemester(semesterIndex);
                            }}
                          >
                            <DeleteIcon style={{ color: "#ff3333" }} />
                          </CardActionArea>
                        </div>
                      ) : (
                        ""
                      )}
                    </Card>
                  )}
                </Grid>
              ))}
            </Grid>
            {state.auth.finalized ? (
              ""
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#3283c9",
                    borderRadius: 5,
                    justifyContent: "flex-start",
                    height: "35px",
                    width: "200px",
                  }}
                  fullWidth
                  onClick={() => addNewSemester()}
                >
                  <AddIcon
                    name="add"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                  <span
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "1px",
                      marginLeft: "15px",
                      fontFamily: "Bahnschrift",

                      fontSize: "16px",
                    }}
                  >
                    ADD SEMESTER
                  </span>
                </Button>
              </div>
            )}
          </Fragment>
        );
      } else if (selectedClassesPage == "SUBJECTS") {
        return (
          <Fragment>
            <div style={{ display: "flex", marginBottom: "15px" }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#3182bd",
                  color: "white",
                  width: "5%",
                  height: "35px",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                onClick={() => setSelectedClassesPage("SEMESTERS")}
              >
                <ArrowBackIcon style={{ fontSize: "25px" }} />
              </Button>
              <div
                textAlign="center"
                style={{
                  color: "#084594",
                  backgroundColor: "#d7ecff",
                  width: "95%",
                  height: "35px",
                  padding: "3px 0px 0px 25px",
                  alignItems: "center",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    marginLeft: "-10px",
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <h2 style={{ fontSize: "20px" }}>
                    {selectedClassesPageClass.split("#edited#")[0] +
                      " - " +
                      selectedClassesPageDivision.split("#edited#")[0] +
                      " - " +
                      selectedClassesPageSemester.split("#edited#")[0] +
                      " - "}
                    Subjects
                  </h2>
                </div>
              </div>
            </div>

            <Fragment>
              {classList[selectedClassesPageClass][selectedClassesPageDivision][
                selectedClassesPageSemester
              ].map((classListSubject, subjectIndex) => (
                <Grid container spacing={3}>
                  <Grid item md={4}>
                    {Object.keys(classListSubject).includes("edit") ? (
                      subjectIndex == selectedClassesSubjectIndex &&
                      classListSubject["edit"] == true ? (
                        <TextField
                          InputProps={{
                            textAlign: "center",
                            disableUnderline: true,
                            style: {
                              color: "#3182bd",
                              textAlign: "center",
                            },
                          }}
                          value={
                            Object.keys(classListSubject)[0] == "edit"
                              ? Object.keys(classListSubject)[1]
                              : Object.keys(classListSubject)[0]
                          }
                          fullWidth
                          size="small"
                          onChange={(e) =>
                            handleSubjectNameChange(
                              e.target.value,
                              subjectIndex
                            )
                          }
                          placeholder={"Name"}
                          variant="outlined"
                        />
                      ) : (
                        <div style={{ marginTop: "-20px" }}>
                          <h3>
                            {Object.keys(classListSubject)[0] == "edit"
                              ? Object.keys(classListSubject)[1]
                              : Object.keys(classListSubject)[0]}
                          </h3>
                        </div>
                      )
                    ) : subjectIndex == selectedClassesSubjectIndex ? (
                      <TextField
                        InputProps={{
                          textAlign: "center",
                          disableUnderline: true,
                          style: {
                            color: "#3182bd",
                            textAlign: "center",
                          },
                        }}
                        value={
                          Object.keys(classListSubject)[0] == "edit"
                            ? Object.keys(classListSubject)[1]
                            : Object.keys(classListSubject)[0]
                        }
                        fullWidth
                        size="small"
                        onChange={(e) =>
                          handleSubjectNameChange(e.target.value, subjectIndex)
                        }
                        placeholder={"Name"}
                        variant="outlined"
                      />
                    ) : (
                      <div style={{ marginTop: "-20px" }}>
                        <h3>
                          {Object.keys(classListSubject)[0] == "edit"
                            ? Object.keys(classListSubject)[1]
                            : Object.keys(classListSubject)[0]}
                        </h3>
                      </div>
                    )}
                  </Grid>
                  <Grid item md={2}>
                    {Object.keys(classListSubject).includes("edit") ? (
                      subjectIndex == selectedClassesSubjectIndex &&
                      classListSubject["edit"] == true ? (
                        <div style={{ display: "flex" }}>
                          <div style={{ display: "flex", marginLeft: "-10px" }}>
                            <Checkbox
                              labelStyle={{ color: "#3182bd" }}
                              iconStyle={{ fill: "white" }}
                              inputStyle={{ color: "#3182bd" }}
                              style={{ marginBottom: "2px", color: "#3182bd" }}
                              checked={
                                Object.keys(classListSubject)[0] == "edit"
                                  ? classListSubject[
                                      Object.keys(classListSubject)[1]
                                    ]["compulsary"]
                                  : classListSubject[
                                      Object.keys(classListSubject)[0]
                                    ]["compulsary"]
                              }
                              onChange={(e) =>
                                handleCompulsaryChange(true, subjectIndex)
                              }
                              value={1}
                              color={"primary"}
                            />
                            <div style={{ marginTop: "10px" }}>Compulsary</div>
                          </div>
                          <div style={{ width: "30px" }}></div>
                          <div style={{ display: "flex", marginLeft: "-10px" }}>
                            <Checkbox
                              labelStyle={{ color: "#3182bd" }}
                              iconStyle={{ fill: "white" }}
                              inputStyle={{ color: "#3182bd" }}
                              style={{ marginBottom: "2px", color: "#3182bd" }}
                              checked={
                                Object.keys(classListSubject)[0] == "edit"
                                  ? !classListSubject[
                                      Object.keys(classListSubject)[1]
                                    ]["compulsary"]
                                  : !classListSubject[
                                      Object.keys(classListSubject)[0]
                                    ]["compulsary"]
                              }
                              onChange={(e) =>
                                handleCompulsaryChange(false, subjectIndex)
                              }
                              value={1}
                              color={"primary"}
                            />
                            <div style={{ marginTop: "10px" }}>Optional</div>
                          </div>
                        </div>
                      ) : (
                        <div>
                          {Object.keys(classListSubject)[0] == "edit"
                            ? classListSubject[
                                Object.keys(classListSubject)[1]
                              ]["compulsary"]
                              ? "Compulsary"
                              : "Optional"
                            : classListSubject[
                                Object.keys(classListSubject)[0]
                              ]["compulsary"]
                            ? "Compulsary"
                            : "Optional"}
                        </div>
                      )
                    ) : subjectIndex == selectedClassesSubjectIndex ? (
                      <div style={{ display: "flex" }}>
                        <div style={{ display: "flex", marginLeft: "-10px" }}>
                          <Checkbox
                            labelStyle={{ color: "#3182bd" }}
                            iconStyle={{ fill: "white" }}
                            inputStyle={{ color: "#3182bd" }}
                            style={{ marginBottom: "2px", color: "#3182bd" }}
                            checked={
                              Object.keys(classListSubject)[0] == "edit"
                                ? classListSubject[
                                    Object.keys(classListSubject)[1]
                                  ]["compulsary"]
                                : classListSubject[
                                    Object.keys(classListSubject)[0]
                                  ]["compulsary"]
                            }
                            onChange={(e) =>
                              handleCompulsaryChange(true, subjectIndex)
                            }
                            value={1}
                            color={"primary"}
                          />
                          <div style={{ marginTop: "10px" }}>Compulsary</div>
                        </div>
                        <div style={{ width: "30px" }}></div>
                        <div style={{ display: "flex", marginLeft: "-10px" }}>
                          <Checkbox
                            labelStyle={{ color: "#3182bd" }}
                            iconStyle={{ fill: "white" }}
                            inputStyle={{ color: "#3182bd" }}
                            style={{ marginBottom: "2px", color: "#3182bd" }}
                            checked={
                              Object.keys(classListSubject)[0] == "edit"
                                ? !classListSubject[
                                    Object.keys(classListSubject)[1]
                                  ]["compulsary"]
                                : !classListSubject[
                                    Object.keys(classListSubject)[0]
                                  ]["compulsary"]
                            }
                            onChange={(e) =>
                              handleCompulsaryChange(false, subjectIndex)
                            }
                            value={1}
                            color={"primary"}
                          />
                          <div style={{ marginTop: "10px" }}>Optional</div>
                        </div>
                      </div>
                    ) : (
                      <div>
                        {classListSubject[Object.keys(classListSubject)[0]][
                          "compulsary"
                        ]
                          ? "Compulsary"
                          : "Optional"}
                      </div>
                    )}
                  </Grid>
                  <Grid item md={4}>
                    {subjectIndex == selectedClassesSubjectIndex ? (
                      <Select
                        value={
                          Object.keys(classListSubject)[0] == "edit"
                            ? classListSubject[
                                Object.keys(classListSubject)[1]
                              ]["teacher"]["email"]
                            : classListSubject[
                                Object.keys(classListSubject)[0]
                              ]["teacher"]["email"]
                        }
                        onChange={(e) =>
                          handleTeacherChange(e.target.value, subjectIndex)
                        }
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        fullWidth
                        variant={"outlined"}
                      >
                        {getTeacherList()}
                      </Select>
                    ) : (
                      <span>
                        {Object.keys(classListSubject)[0] == "edit"
                          ? classListSubject[Object.keys(classListSubject)[1]][
                              "teacher"
                            ]["email"]
                          : classListSubject[Object.keys(classListSubject)[0]][
                              "teacher"
                            ]["email"]}
                      </span>
                    )}
                  </Grid>
                  <Grid md={2}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop:
                          subjectIndex == selectedClassesSubjectIndex
                            ? "28px"
                            : "20px",
                      }}
                    >
                      {subjectIndex == selectedClassesSubjectIndex ? (
                        <Button
                          onClick={() => {
                            setSelectedClassesSubjectIndex(9999);
                          }}
                          style={{ borderRadius: "20px" }}
                        >
                          <SaveIcon />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => {
                            setSelectedClassesSubjectIndex(subjectIndex);
                          }}
                          style={{ borderRadius: "20px" }}
                        >
                          <EditIcon />
                        </Button>
                      )}

                      <Button
                        onClick={() => {
                          deleteSubject(subjectIndex);
                        }}
                        style={{ borderRadius: "20px" }}
                      >
                        <DeleteIcon style={{ color: "#ff3333" }} />
                      </Button>
                    </div>
                  </Grid>
                </Grid>
              ))}
              {/* {state.auth.finalized ? (
                ""
              ) : ( */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "30px",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "#3283c9",
                    borderRadius: 5,
                    justifyContent: "flex-start",
                    height: "35px",
                    width: "200px",
                  }}
                  fullWidth
                  onClick={() => addNewSubject()}
                >
                  <AddIcon
                    name="add"
                    style={{ color: "white", fontSize: "30px" }}
                  />
                  <span
                    style={{
                      textAlign: "center",
                      color: "white",
                      marginTop: "1px",
                      marginLeft: "18px",
                      fontFamily: "Bahnschrift",

                      fontSize: "16px",
                    }}
                  >
                    ADD SUBJECT
                  </span>
                </Button>
              </div>
              {/* )} */}
            </Fragment>
          </Fragment>
        );
      }
    } else if (selectedPage == "FEES") {
      return (
        <Grid container spacing={5}>
          <Grid item xs={8}>
            <Grid container spacing={1.5}>
              <Grid item md={6}>
                <span
                  style={{
                    color: "#3182bd",
                    fontFamily: "Lato-Regular",
                    fontSize: "14px",
                  }}
                >
                  Class
                </span>
                <Select
                  value={classs}
                  onChange={(e) => handleClassChange(e)}
                  style={{
                    marginTop: "2px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                  fullWidth
                  variant={"outlined"}
                >
                  {getClassList()}
                </Select>
              </Grid>
              <Grid item md={6}>
                <span
                  style={{
                    color: "#3182bd",
                    fontFamily: "Lato-Regular",
                    fontSize: "14px",
                  }}
                >
                  Division
                </span>
                <Select
                  value={division}
                  onChange={(e) => handleDivisionChange(e)}
                  style={{
                    marginTop: "2px",
                    height: "40px",
                    marginBottom: "5px",
                  }}
                  fullWidth
                  variant={"outlined"}
                >
                  {getDivisionList()}
                </Select>
              </Grid>
            </Grid>
            <br></br>
            {Object.keys(feesStructure[classs][division]).length == 0 ? (
              <div
                style={{
                  margin: "-3px 0px 0px 12px",
                  color: colorList2[colorIndex],
                  fontSize: "15px",
                }}
              >
                Press The " + " button to add Expenses.
              </div>
            ) : (
              ""
            )}
            {Object.keys(feesStructure[classs][division]).map((fees, index) => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "50px",
                  marginBottom: "10px",
                }}
              >
                {feesItemIndex == index ? (
                  <TextField
                    style={{ marginTop: "5px" }}
                    fullWidth
                    size="small"
                    placeholder="Expense"
                    variant="outlined"
                    value={fees}
                    onChange={(e) => editFeesExpense(index, e.target.value)}
                  />
                ) : (
                  <div style={{ width: "70%" }}>
                    <h3 style={{ marginTop: "12px" }}>{fees}</h3>
                  </div>
                )}
                {feesItemIndex == index ? (
                  <TextField
                    style={{ marginTop: "5px", marginLeft: "15px" }}
                    size="small"
                    placeholder="Cost"
                    variant="outlined"
                    value={feesStructure[classs][division][fees]}
                    onChange={(e) => editFeesCost(index, e.target.value)}
                  />
                ) : (
                  <div style={{ width: "30%" }}>
                    <h3 style={{ marginLeft: "20px", marginTop: "12px" }}>
                      &#8377;{" " + feesStructure[classs][division][fees]}
                    </h3>
                  </div>
                )}
                <div
                  style={{
                    display: "flex",
                    marginLeft: "30px",
                    justifyContent: "center",
                  }}
                >
                  {feesItemIndex == index ? (
                    <Button
                      onClick={() => setFeesItemIndex(9999)}
                      style={{ borderRadius: "20px" }}
                    >
                      <SaveIcon />
                    </Button>
                  ) : (
                    <Button
                      onClick={() => setFeesItemIndex(index)}
                      style={{ borderRadius: "20px" }}
                    >
                      <EditIcon />
                    </Button>
                  )}

                  <Button
                    onClick={() => deleteFeesExpense(index)}
                    style={{ borderRadius: "20px" }}
                  >
                    <DeleteIcon style={{ color: "#ff3333" }} />
                  </Button>
                </div>
              </div>
            ))}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "20px",
              }}
            >
              <Fab
                size="small"
                style={{
                  boxShadow: "none",
                  backgroundColor: "#3182bd",
                }}
                onClick={() => addFeesExpense()}
                aria-label="add"
              >
                <AddIcon
                  style={{
                    color: "white",
                  }}
                />
              </Fab>
            </div>
          </Grid>
          <Grid
            item
            xs={4}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "87vh",
            }}
          >
            <div>
              {Object.keys(feesStructure[classs][division]).map(
                (fees, index) => (
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontFamily: "Lato-Regular" }}>{fees}</div>
                    <div style={{ fontFamily: "Lato-Regular" }}>
                      {feesStructure[classs][division][fees]}
                    </div>
                  </div>
                )
              )}
              {Object.keys(feesStructure[classs][division]).length !== 0 ? (
                <div>
                  <div
                    style={{
                      width: "100%",
                      borderTop: "1px solid black",
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                  ></div>
                  <div
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div style={{ fontFamily: "Lato-Bold" }}>Total</div>{" "}
                    <div style={{ fontFamily: "Lato-Bold" }}>
                      {" "}
                      &#8377;{getTotalFees()}
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <Button
              onClick={() => updateFeesStructure()}
              variant="contained"
              style={{
                width: "100%",
                marginTop: "15px",
                height: "35px",
                backgroundColor: "#3182bd",
              }}
            >
              <h3>Update Fees Structure</h3>
            </Button>
          </Grid>
        </Grid>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar tabs={[]} />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={1.5}>
                    <Grid item md={2}></Grid>
                    <Grid item md={2}>
                      {selectedPage == "INSTITUTION" ? (
                        <Button
                          fullWidth
                          value="INSTITUTION"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            INSTITUTION
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="INSTITUTION"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            INSTITUTION
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={2}>
                      {selectedPage == "TIMETABLE" ? (
                        <Button
                          fullWidth
                          value="TIMETABLE"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            TIMETABLE
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="TIMETABLE"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            TIMETABLE
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={2}>
                      {selectedPage == "CLASSES" ? (
                        <Button
                          fullWidth
                          value={"CLASSES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            CLASSES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"CLASSES"}
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            CLASSES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={2}>
                      {selectedPage == "PROMOTE" ? (
                        <Button
                          fullWidth
                          value="PROMOTE"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            PROMOTE
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="PROMOTE"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            PROMOTE
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    <Grid item md={2}>
                      {selectedPage == "FEES" ? (
                        <Button
                          fullWidth
                          value="FEES"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            FEES
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value="FEES"
                          onClick={handlePageSelect}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            FEES
                          </h3>
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                }
              />

              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open4}
                onClose={() => setOpen4(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open4}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen4(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Finalize this Promotion?</h3>
                      <span>
                        This action cannot be reversed. Changes will be made.
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => {
                            if (
                              semesterPromotePageFrom ==
                                semesterPromotePageTo &&
                              classsPromotePageFrom == classsPromotePageTo &&
                              state.auth.classType == "STANDARDS"
                            ) {
                              setPromoteSubmitAnswer("YES");
                              setOpen5(true);
                            } else {
                              if (
                                classsPromotePageFrom == classsPromotePageTo &&
                                state.auth.classType == "STANDARDS"
                              ) {
                                setOpen6(true);
                              } else {
                                setPromoteSubmitAnswer("NO");
                                setOpen5(true);
                              }
                            }
                          }}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen4(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open5}
                onClose={() => setOpen5(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open5}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen5(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>
                        Do you want to continue the attendance to next semester?
                      </h3>
                      <span>
                        This semester's attendace will be carryforwarded to
                        next.
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => {
                            setPromoteSubmitAnswer("YES");
                            setOpen6(true);
                          }}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => {
                            setPromoteSubmitAnswer("NO");
                            setOpen6(true);
                          }}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open6}
                onClose={() => setOpen6(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open6}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen6(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>
                        Edit semester subjects and classes for this semester?
                      </h3>
                      <span>
                        You will be able to add / edit semester subject and
                        teachers for {" " + semesterPromotePageTo + "."}
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => {
                            promoteSubmit("YES");
                          }}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => promoteSubmit("NO")}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open3}
                onClose={() => setOpen3(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open3}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen3(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Are you sure?</h3>
                      <span>
                        This action cannot be reversed. All the students checked
                        will be promoted to the selected classes and semesters.
                        These actions cannot be reversed. All the students and
                        their parents will be notified. Do you cant to continue?
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => {
                            setOpen4(true);
                          }}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen3(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open2}
                onClose={() => setOpen2(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open2}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen2(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>Finalize this class structure?</h3>
                      <span>
                        This action cannot be reversed. It will be set till next
                        academic year. We recommend checking all classes,
                        divisions, and semesters again. All Subjects and
                        Teachers must be added before adding students.
                      </span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => updateClassList()}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen2(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={() => setOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                  timeout: 500,
                }}
              >
                <Fade in={open}>
                  <div
                    style={{
                      position: "absolute",
                      top: "50%",
                      backgroundColor: "white",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: 400,
                      padding: "20px",
                    }}
                  >
                    <div style={{ marginTop: "-25px" }}>
                      <div
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <IconButton size="small">
                          <CloseIcon
                            onClick={() => setOpen(false)}
                            style={{ color: "#3182bd" }}
                          />
                        </IconButton>
                      </div>
                      <h3>
                        Update Time Table for {classs} {division}?
                      </h3>
                      <span>This action cannot be reversed.</span>
                      <br></br>
                      <br></br>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          onClick={() => updateTimeTable()}
                          style={{ height: "30px" }}
                          variant="contained"
                        >
                          Yes
                        </Button>
                        <Button
                          style={{ height: "30px", marginLeft: "10px" }}
                          variant="contained"
                          onClick={() => setOpen(false)}
                        >
                          No
                        </Button>
                      </div>
                    </div>
                  </div>
                </Fade>
              </Modal>
              <div
                style={{
                  margin: "65px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                {selectedPageContent()}
                {selectedPage == "CLASSES" ? (
                  <Button
                    fullWidth
                    onClick={() => setOpen2(true)}
                    p={2}
                    style={{
                      position: "fixed",
                      bottom: 30,
                      right: 30,
                      backgroundColor: "#3182bd",
                      color: "white",
                      height: "30px",
                      width: "200px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      {state.auth.finalized
                        ? "UPDATE CLASSES"
                        : "FINALIZE CLASSES"}
                    </h3>
                  </Button>
                ) : (
                  ""
                )}
                {selectedPage == "PROMOTE" && state.auth.finalized ? (
                  <Button
                    fullWidth
                    onClick={() => setOpen3(true)}
                    p={2}
                    style={{
                      position: "fixed",
                      bottom: 30,
                      right: 30,
                      backgroundColor: "#3182bd",
                      color: "white",
                      height: "30px",
                      width: "200px",
                    }}
                    borderRadius={6}
                  >
                    <h3
                      style={{
                        marginTop: "17px",
                        fontSize: "15px",
                      }}
                    >
                      PROMOTE STUDENTS
                    </h3>
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Principal");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Settings;
