import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";

import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import PublicIcon from "@mui/icons-material/Public";
import { Line } from "react-chartjs-2";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import GavelIcon from "@mui/icons-material/Gavel";
import BallotIcon from "@mui/icons-material/Ballot";
import ComputerIcon from "@mui/icons-material/Computer";
import HistoryIcon from "@mui/icons-material/History";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import CalendarPicker from "@mui/lab/CalendarPicker";
import { Chart } from "chart.js/auto";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import TextField from "@mui/material/TextField";
import Rating from "@mui/material/Rating";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import moment from "moment";
import Fab from "@mui/material/Fab";

const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3182bd",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Feedback() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [selectedDate, setSelectedDate] = useState(new Date());

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [selectedPage, setSelectedPage] = useState(
    params.get("tab") ? params.get("tab") : "COMPLAINTS"
  );
  const [randomText, setRandomText] = useState("");

  const [complaintList, setComplaintList] = useState([]);
  const [feedbackList, setFeedbackList] = useState([]);

  function handleResolution(index, resolution) {
    var newComplaintList = complaintList;
    delete newComplaintList[index]["resolution"];
    newComplaintList[index]["resolution"] = resolution;
    setRandomText(makeid(2));
    setComplaintList(newComplaintList);
  }

  function getComplaints() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/get_complaints_principal", body, config)
      .then((res) => {
        setComplaintList(res.data);
        setIsLoading(false);
      });
  }

  function getFeedback() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/get_feedback_principal", body, config)
      .then((res) => {
        setFeedbackList(res.data);
        setIsLoading(false);
      });
  }

  function resolveComplaint(id, status, resolution) {
    setIsSubmitting(true);
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["institution_id"] = state.auth.user.institution_id;
    body["name"] = state.auth.user.firstname + " " + state.auth.user.lastname;
    body["complaint_id"] = id;
    body["resolution"] = resolution;
    body["status"] = status;
    body["date"] = moment().format("DD-MM-YYYY");
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/update_complaint_status", body, config)
      .then((res) => {
        getComplaints();
        setIsSubmitting(false);
        createAlert("SUCCESS", "Success.", "Complaint " + status);
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      getComplaints();
      getFeedback();
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getComplaints();
    getFeedback();
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  var colorIndex = 0;

  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function getContent() {
    if (selectedPage == "COMPLAINTS") {
      return (
        <Fragment>
          {complaintList.length == 0 ? (
            <div
              style={{
                marginTop: "200px",
                height: "100px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <Fab
                  size="large"
                  style={{
                    border: "3px solid #aaa",
                    boxShadow: "none",
                    backgroundColor: "transparent",
                  }}
                  aria-label="add"
                >
                  <PriorityHighIcon
                    style={{
                      color: "grey",
                    }}
                  />
                </Fab>
                <br />
                <h4 style={{ color: "grey" }}>No complaints submitted.</h4>
              </div>
            </div>
          ) : (
            complaintList.map((complaint, index) => (
              <Fragment>
                <Box
                  p={2}
                  style={{
                    marginBottom: "15px",
                    borderRadius: "15px",
                    border:
                      complaint["status"] == "PENDING"
                        ? "1.5px solid" + "#3182bd"
                        : "none",
                    backgroundColor:
                      complaint["status"] == "PENDING"
                        ? "white"
                        : complaint["status"] == "RESOLVED"
                        ? colorList[0]
                        : colorList[2],
                    color: "black",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "90%" }}>
                      <div style={{ marginTop: "-30px" }}>
                        <h3
                          style={{
                            fontFamily: "Lato-Bold",
                            color:
                              complaint["status"] == "PENDING"
                                ? "#3182bd"
                                : complaint["status"] == "RESOLVED"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          {complaint["subject"]}
                        </h3>
                      </div>
                      <div style={{ marginTop: "-25px" }}>
                        <h4>{complaint["class"]}</h4>
                      </div>
                      <div
                        style={{
                          marginTop: "-15px",
                          marginBottom: "5px",
                          lineHeight: "18px",
                        }}
                      >
                        {complaint["description"]}
                      </div>
                      {complaint["status"] != "PENDING" ? (
                        <div>
                          <b>Resolution:</b>
                          <i>{" " + complaint["resolution"]}</i>
                        </div>
                      ) : (
                        <div>
                          <br></br>
                          <b>Resolve / Reject</b>
                          <TextField
                            value={complaint["resolution"]}
                            style={{
                              marginTop: "2px",
                              backgroundColor: "white",
                            }}
                            onChange={(e) =>
                              handleResolution(index, e.target.value)
                            }
                            fullWidth
                            placeholder="Resolution of complaint / Reason for rejecting."
                            variant="outlined"
                            multiline
                            rows={4}
                          />

                          <div
                            style={{
                              display: "flex",
                              marginTop: "15px",
                              justifyContent: "flex-end",
                            }}
                          >
                            {isSubmitting ? (
                              <CircularProgress
                                style={{ marginRight: "15px" }}
                              />
                            ) : (
                              ""
                            )}
                            <Button
                              style={{
                                fontSize: "15px",
                                height: "35px",
                                marginTop: "-1px",
                                backgroundColor: colorList2[0],
                              }}
                              onClick={() =>
                                resolveComplaint(
                                  complaint["id"],
                                  "RESOLVED",
                                  complaint["resolution"]
                                )
                              }
                              variant="contained"
                              color="primary"
                            >
                              <h4>Resolve</h4>
                            </Button>
                            <Button
                              style={{
                                marginLeft: "15px",
                                fontSize: "15px",
                                height: "35px",
                                marginTop: "-1px",
                                backgroundColor: colorList2[2],
                              }}
                              onClick={() =>
                                resolveComplaint(
                                  complaint["id"],
                                  "REJECTED",
                                  complaint["resolution"]
                                )
                              }
                              variant="contained"
                              color="primary"
                            >
                              <h4>Reject</h4>
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>

                    <div
                      style={{
                        width: "100px",
                        marginTop: "8px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          textAlign="center"
                          p={1}
                          style={{
                            height: "35px",
                            width: "35px",
                            borderRadius: "60px",
                            backgroundColor:
                              complaint["status"] == "PENDING"
                                ? "#3182bd"
                                : complaint["status"] == "RESOLVED"
                                ? colorList2[0]
                                : colorList2[2],
                            color: "white",
                          }}
                        >
                          {complaint["status"] == "PENDING" ? (
                            <PriorityHighIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5px",
                              }}
                            />
                          ) : complaint["status"] == "APPROVED" ? (
                            <CheckIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5px",
                              }}
                            />
                          ) : (
                            <CloseIcon
                              style={{
                                fontSize: "30px",
                                margin: "-5px 0px 0px -5px",
                              }}
                            />
                          )}
                        </Box>
                        <b
                          style={{
                            marginTop: "5px",
                            color:
                              complaint["status"] == "PENDING"
                                ? "#3182bd"
                                : complaint["status"] == "RESOLVED"
                                ? colorList2[0]
                                : colorList2[2],
                          }}
                        >
                          {complaint["status"]}
                        </b>
                      </div>
                    </div>
                  </div>
                </Box>
              </Fragment>
            ))
          )}
        </Fragment>
      );
    } else {
      return (
        <div
          style={{
            width: "100%",
          }}
        >
          {feedbackList.map((teacher, index) => (
            <Fragment>
              {Object.keys(teacher["feedback"]).length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No feedback submitted.</h4>
                  </div>
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                  }}
                >
                  <b style={{ fontSize: "22px" }}>{teacher["name"]}</b>

                  <div
                    style={{
                      marginTop: "15px",
                    }}
                  >
                    {teacher["feedback"]["ratings"].map((rating) => (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {Object.keys(rating)[0]}
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          <Rating
                            name="half-rating-read"
                            value={
                              rating[Object.keys(rating)[0]].reduce(
                                (a, b) => a + b,
                                0
                              ) / rating[Object.keys(rating)[0]].length
                            }
                            precision={0.5}
                          />
                          <div style={{ width: "50px" }}>
                            <h3
                              style={{
                                margin: "-1px 0px 0px 10px",
                                textAlign: "left",
                              }}
                            >
                              {(
                                rating[Object.keys(rating)[0]].reduce(
                                  (a, b) => a + b,
                                  0
                                ) / rating[Object.keys(rating)[0]].length
                              )
                                .toString()
                                .substring(0, 4)}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div
                    onClick={handleExpandClick}
                    style={{
                      backgroundColor: "d7ecff",
                      padding: "10px",
                      marginTop: "15px",
                      borderRadius: "6px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <b>Suggestions</b>
                      <IconButton
                        style={{
                          backgroundColor: "#3182bd",
                          color: "white",
                          height: "10px",
                          width: "10px",
                          marginLeft: "10px",
                        }}
                        onClick={handleExpandClick}
                        aria-expanded={expanded}
                        aria-label="show more"
                        size="large"
                      >
                        {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </IconButton>
                    </div>

                    <Collapse
                      in={expanded}
                      timeout="auto"
                      unmountOnExit
                      style={{
                        marginTop: "5px",
                        backgroundColor: "d7ecff",
                      }}
                    >
                      {teacher["feedback"]["suggestions"].map((suggestion) => (
                        <span>
                          {suggestion}
                          <br></br>
                        </span>
                      ))}
                    </Collapse>
                  </div>
                </div>
              )}
            </Fragment>
          ))}
        </div>
      );
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar
                tabs={
                  <Grid container spacing={2} justifyContent="flex-end">
                    <Grid item md={4}>
                      {selectedPage == "COMPLAINTS" ? (
                        <Button
                          fullWidth
                          value={"COMPLAINTS"}
                          p={2}
                          style={{
                            backgroundColor: "#3182bd",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                            }}
                          >
                            COMPLAINTS
                          </h3>
                        </Button>
                      ) : (
                        <Button
                          fullWidth
                          value={"COMPLAINTS"}
                          onClick={() => {
                            setSelectedPage("COMPLAINTS");
                          }}
                          p={2}
                          style={{
                            backgroundColor: "#d7ecff",
                            color: "white",
                            height: "30px",
                          }}
                          borderRadius={6}
                        >
                          <h3
                            style={{
                              marginTop: "17px",
                              fontSize: "15px",
                              color: "#084594",
                            }}
                          >
                            COMPLAINTS
                          </h3>
                        </Button>
                      )}
                    </Grid>
                    {/* <Grid item md={4}>
                        {selectedPage == "FEEDBACK" ? (
                          <Button
                            fullWidth
                            value="FEEDBACK"
                            p={2}
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              FEEDBACK
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value="FEEDBACK"
                            onClick={() => {
                              setSelectedPage("FEEDBACK");
                            }}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "white",
                              height: "30px",
                            }}
                            borderRadius={6}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              FEEDBACK
                            </h3>
                          </Button>
                        )}
                      </Grid> */}
                  </Grid>
                }
              />
              <div
                style={{
                  margin: "65px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                {getContent()}
              </div>
            </Fragment>
          );
        }
      } else {
        createAlert("ERROR", "Permission Denied.", "Please Login as Principal");
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Feedback;
