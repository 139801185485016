import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import makeStyles from "@mui/styles/makeStyles";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import GetAppIcon from "@mui/icons-material/GetApp";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import Checkbox from "@mui/material/Checkbox";
import moment from "moment";
import ButtonBase from "@mui/material/ButtonBase";
import html2pdf from "html2pdf.js";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import Fab from "@mui/material/Fab";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const colorList = [
  "#e5f5f9",
  "#e0ecf4",
  "#fee8c8",
  "#ece7f2",
  "#f0f0f0",
  "#fde0dd",
  "#efedf5",
  "#e5f5e0",
  "#d7ecff",
  "#fee6ce",
];
const colorList2 = [
  "#2ca25f",
  "#8856a7",
  "#e34a33",
  "#1c9099",
  "#636363",
  "#c51b8a",
  "#756bb1",
  "#31a354",
  "#3283c9",
  "#e6550d",
];

const colorList3 = [
  "#99d8c9",
  "#9ebcda",
  "#fdbb84",
  "#a6bddb",
  "#bdbdbd",
  "#fa9fb5",
  "#bcbddc",
  "#addd8e",
  "#9ecae1",
  "#a1d99b",
];

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Results() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();

  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);

  const [selectedPage, setSelectedPage] = useState("STUDENTS");
  const [studentIndex, setStudentIndex] = useState(0);
  const [resultIndex, setResultIndex] = useState(0);

  const [random, setRandom] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingResult, setIsLoadingResult] = useState(false);
  const [results, setResults] = useState([]);
  const [exams, setExams] = useState([]);
  const [classList, setClassList] = useState([]);
  const [classs, setClass] = useState("");
  const [division, setDivision] = useState("");
  const [semester, setSemester] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [selectedExam, setSelectedExam] = useState(0);
  const [isReady, setIsReady] = useState(false);
  const [resultPage, setResultPage] = useState("CHECK");
  const [submitResult, setSubmitResult] = useState({});
  const [tempStudentList, setTempStudentList] = useState([]);
  const [incompleteResult, setIncompleteResult] = useState(false);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [openIncomplete, setOpenIncomplete] = useState(false);

  function getClassList() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionList() {
    var classes = Object.keys(classList[classs]).map((division, index) => (
      <MenuItem value={division}>{division}</MenuItem>
    ));
    return classes;
  }

  function getSemesterList() {
    var semesters = Object.keys(classList[classs][division]).map(
      (semester, index) => <MenuItem value={semester}>{semester}</MenuItem>
    );
    return semesters;
  }

  function getResult(email) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["email"] = email;
    body = JSON.stringify(body);

    axios.post(API_URL + "/get_result", body, config).then((res) => {
      setIsLoadingResult(false);
      setResults(res.data);
    });
  }

  function getExams(cla, div) {
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };

    var body = {};
    body["class_id"] = cla;
    body["division"] = div;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_exams", body, config)
      .then((res) => {
        setExams(res.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  }

  function getStudents(cla, div) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["class_id"] = cla;
    body["division"] = div;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/get_students_list_class_division", body, config)
      .then((res) => {
        setStudentList(res.data);
        setRandom(makeid(4));
      });
  }

  function checkIncomplete() {
    for (var i = 0; i < studentList.length; i++) {
      if (tempStudentList.includes(studentList[i]["email"])) {
      } else {
        return true;
      }
    }
    return false;
  }

  function handleMarksChange(email, marksObtained, subjectName) {
    var tempResult = submitResult;

    for (var i = 0; i < tempResult[email]["results"].length; i++) {
      if (Object.keys(tempResult[email]["results"][i])[0] == subjectName) {
        tempResult[email]["results"][i][
          Object.keys(tempResult[email]["results"][i])[0]
        ]["marks_obtained"] = marksObtained;
        tempResult[email]["marks_obtained"] = getTotalMarks().toString();
        tempResult[email]["percent"] = (
          (getTotalMarks() / parseInt(exams[selectedExam]["marks_total"])) *
          100
        ).toFixed(2);
        setSubmitResult(tempResult);
        setRandom(makeid(4));

        return null;
      }
    }
  }

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      body["institution_id"] = state.auth.user.institution_id;

      body = JSON.stringify(body);
      axios.post(API_URL + "/get_class_list", body, config).then((res) => {
        var classListTemp = res.data;
        setClassList(classListTemp);
        setClass(Object.keys(classListTemp)[0]);
        setDivision(
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
        setSemester(
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ]
          )[0]
        );
        setIsReady(true);
        getStudents(
          Object.keys(classListTemp)[0],
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
        getExams(
          Object.keys(classListTemp)[0],
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
      });
    }
  }, []);

  if (state.auth.refresh == "REFRESH_DATA") {
    getExams(classs, division);
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function handleSemesterChange(e) {
    setSemester(e.target.value);
    getExams(classs, division);
  }

  function handleClassChange(e) {
    if (resultPage == "CHECK") {
      setSelectedPage("STUDENTS");
    } else {
      setSelectedPage("EXAMS");
    }
    setClass(e.target.value);
    setIsLoading(true);
    getExams(e.target.value, division);
    getStudents(e.target.value, division);
  }

  function deleteResult() {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["result_id"] = results[resultIndex]["id"];
    body["semester"] = semester;
    body["email"] = studentList[studentIndex]["email"];

    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/delete_student_result", body, config)
      .then((res) => {
        setSelectedPage("STUDENTS");
        getExams(classs, division);
        setOpen2(false);
        createAlert("SUCCESS", "Success.", "Exam deleted.");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function handleDivisionChange(e) {
    if (resultPage == "CHECK") {
      setSelectedPage("STUDENTS");
    } else {
      setSelectedPage("EXAMS");
    }
    setDivision(e.target.value);
    setIsLoading(true);
    getExams(classs, e.target.value);
    getStudents(classs, e.target.value);
  }

  function getTotalMarks() {
    var total = 0;
    for (
      var i = 0;
      i < submitResult[Object.keys(submitResult)[0]]["results"].length;
      i++
    ) {
      if (
        submitResult[Object.keys(submitResult)[0]]["results"][i][
          Object.keys(
            submitResult[Object.keys(submitResult)[0]]["results"][i]
          )[0]
        ]["marks_obtained"] != ""
      ) {
        total += parseInt(
          submitResult[Object.keys(submitResult)[0]]["results"][i][
            Object.keys(
              submitResult[Object.keys(submitResult)[0]]["results"][i]
            )[0]
          ]["marks_obtained"]
        );
      }
    }
    return total;
  }

  function handleIncompleteResult() {
    setIncompleteResult(true);
  }
  function publishResults(resultId, semester) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["result_id"] = resultId;
    body["class_id"] = classs;
    body["division"] = division;
    body["semester"] = semester;
    body["current_year"] = state.auth.current_year;
    body["institution_id"] = state.auth.user.institution_id;
    body["date"] = moment().format("DD-MM-YYYY");
    body = JSON.stringify(body);

    axios
      .post(API_URL + "/publish_results", body, config)
      .then((res) => {
        getExams(classs, division);
        setIsSubmitting(false);
        setSelectedPage("EXAMS");
        setOpen(false);
        createAlert("SUCCESS", "Success.", "Results Published");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function saveResult(resultId, email) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["result_id"] = resultId;
    body["student_result"] = Object.values(submitResult)[0];
    body["email"] = email;
    body["class_id"] = classs;
    body["division"] = division;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/save_student_results", body, config)
      .then((res) => {
        setIsSaving(false);
        getTempResultStudents(resultId);
        createAlert("SUCCESS", "Success.", "Result saved");
      })
      .catch((err) => {
        createAlert("ERROR", "Error", "An error occured.");
      });
  }

  function getTempResultStudents(resultId) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["result_id"] = resultId;
    body["institution_id"] = state.auth.user.institution_id;
    body = JSON.stringify(body);
    axios
      .post(API_URL + "/get_temp_result_students", body, config)
      .then((res) => {
        setTempStudentList(res.data);
        setIsReady(true);
      });
  }

  function handlePassCheck(email, passed) {
    var tempResult = submitResult;
    tempResult[email]["result"] = passed ? "PASSED" : "FAILED";
    setSubmitResult(tempResult);
    setRandom(makeid(4));
    return null;
  }

  function getContent() {
    if (resultPage == "CHECK") {
      if (selectedPage == "STUDENTS") {
        return (
          <Grid container spacing={1.5}>
            {studentList.map((student, index) => (
              <Grid item xs={6}>
                <CardActionArea
                  sx={[
                    {
                      borderRadius: "6px",
                      border: "1.5px solid #d7ecff",
                      transition: "background-color 0.5s, border-color 0.5s",
                    },
                    (theme) => ({
                      "&:hover": {
                        backgroundColor: "#ebf5ff",
                        borderColor: "#3182bd",
                      },
                      ".MuiCardActionArea-focusHighlight": {
                        background: "transparent",
                      },

                      "&& .MuiTouchRipple-child": {
                        backgroundColor: "#084594",
                      },
                    }),
                  ]}
                  style={{
                    padding: "10px",
                    borderRadius: "6px",
                    marginBottom: "5px",
                    border: "2px solid #d7ecff",
                    borderRadius: "6px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setSelectedPage("STUDENT");
                    setIsLoadingResult(true);
                    setStudentIndex(index);
                  }}
                >
                  <Grid container justifyContent="space-between">
                    <Grid item md={10}>
                      <b
                        style={{
                          fontSize: "20px",
                          fontFamily: "Lato-Bold",
                          color: "#084594",
                        }}
                      >
                        {student["name"]}
                      </b>
                      <br></br>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "16px",
                        }}
                      >
                        {" "}
                        Roll no
                        <span style={{ fontFamily: "Lato-Bold" }}>
                          {" " + student["roll_number"]}
                        </span>
                      </span>
                    </Grid>

                    <Grid item md={2}>
                      <ButtonBase
                        p={2}
                        value={index}
                        fullWidth
                        style={{
                          backgroundColor: "#31a354",
                          color: "white",
                          height: "25px",
                          width: "60px",
                          borderRadius: "2px",
                          margin: "8px 5px 0px 5px",
                        }}
                        onClick={() => {
                          setSelectedPage("STUDENT");
                          setIsLoadingResult(true);
                          setStudentIndex(index);
                        }}
                      >
                        <b>
                          <p
                            style={{
                              marginTop: "15px",
                              fontFamily: "Bahnschrift",
                              fontWeight: "lighter",
                            }}
                          >
                            VIEW
                          </p>
                        </b>
                      </ButtonBase>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Grid>
            ))}
          </Grid>
        );
      } else if (selectedPage == "STUDENT") {
        if (isLoadingResult) {
          getResult(studentList[studentIndex]["email"]);
          return (
            <div
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                marginTop: "46vh",
              }}
            >
              <CircularProgress />
            </div>
          );
        } else {
          return (
            <Fragment>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  fontFamily: "Bahnschrift",
                  fontWeight: "lighter",
                }}
              >
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#3182bd",
                    color: "white",
                    width: "5%",
                    height: "35px",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                  onClick={() => setSelectedPage("STUDENTS")}
                >
                  <ArrowBackIcon style={{ fontSize: "25px" }} />
                </Button>
                <div
                  textAlign="center"
                  style={{
                    color: "#084594",
                    backgroundColor: "#d7ecff",
                    width: "95%",
                    height: "35px",
                    padding: "3px 15px 0px 25px",
                    alignItems: "center",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    fontSize: "18px",
                  }}
                >
                  <h3 style={{ marginLeft: "-10px" }}>
                    {studentList[studentIndex]["name"]}
                  </h3>
                </div>
              </div>
              <Grid container spacing={1.5} style={{ marginTop: "4px" }}>
                {results.length != 0 ? (
                  results.map((result, index) => (
                    <Grid item md={4}>
                      <Card
                        style={{
                          minHeight: "100%",
                          backgroundColor: "white",
                          boxShadow: "none",
                        }}
                        onClick={(e) => {
                          setResultIndex(index);
                          setSelectedPage("RESULT");
                        }}
                      >
                        <CardActionArea
                          sx={[
                            {
                              borderRadius: "6px",
                              border: "1.5px solid #d7ecff",
                              transition:
                                "background-color 0.5s, border-color 0.5s",
                            },
                            (theme) => ({
                              "&:hover": {
                                backgroundColor: "#ebf5ff",
                                borderColor: "#3182bd",
                              },
                              ".MuiCardActionArea-focusHighlight": {
                                background: "transparent",
                              },

                              "&& .MuiTouchRipple-child": {
                                backgroundColor: "#084594",
                              },
                            }),
                          ]}
                          style={{ padding: "8px 7px 12px 13px" }}
                        >
                          <h2
                            style={{
                              marginTop: "0px",
                              color: "#084594",

                              fontFamily: "Lato-Bold",
                            }}
                          >
                            {result["test"]}
                          </h2>

                          <div
                            style={{
                              position: "relative",
                              bottom: "53px",
                              left: "16.5vw",
                              fontFamily: "Bahnschrift",
                              height: "30px",
                              width: "55px",
                              backgroundColor: "#3182bd",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "20px",
                              color: "white",
                              borderRadius: "5px",
                            }}
                          >
                            <span style={{ marginTop: "6px" }}>
                              {result["percent"].split(".")[0]}%
                            </span>
                          </div>
                          <ButtonBase
                            p={2}
                            value={index}
                            fullWidth
                            style={{
                              position: "relative",
                              bottom: "43px",
                              left: "17.25vw",
                            }}
                            onClick={(e) => {
                              setResultIndex(index);
                              setOpen2(true);
                            }}
                          >
                            <DeleteIcon
                              style={{
                                color: "#ff3333",
                              }}
                            />
                          </ButtonBase>
                          <p
                            style={{
                              margin: "-47px 0px 15px 1px",
                              fontSize: "14px",
                              fontFamily: "Lato-Regular",
                              color: "#3182bd",
                            }}
                          >
                            {result["semester"]}
                          </p>

                          <div
                            style={{
                              width: "100%",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Lato-Bold",
                                color: "#084594",
                              }}
                            >
                              Start{" "}
                            </span>
                            <span
                              style={{
                                margin: "0px 0px 0px 6px",
                                fontSize: "14px",
                                fontFamily: "Lato-Regular",
                                color: "#3182bd",
                              }}
                            >
                              {moment(
                                result["start_date"],
                                "DD-MM-YYYY"
                              ).format("Do MMMM, YYYY")}
                            </span>
                            <div style={{ display: "flex" }}>
                              <span
                                style={{
                                  color: "#084594",
                                  fontFamily: "Lato-Bold",
                                }}
                              >
                                End{" "}
                              </span>
                              <span
                                style={{
                                  margin: "0px 0px 0px 16px",
                                  fontSize: "14px",
                                  fontFamily: "Lato-Regular",
                                  color: "#3182bd",
                                }}
                              >
                                {moment(
                                  result["end_date"],
                                  "DD-MM-YYYY"
                                ).format("Do MMMM, YYYY")}
                              </span>
                            </div>
                          </div>
                        </CardActionArea>
                      </Card>
                    </Grid>
                  ))
                ) : (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>No results uploaded.</h4>
                    </div>
                  </div>
                )}
              </Grid>
            </Fragment>
          );
        }
      } else if (selectedPage == "RESULT") {
        return (
          <Fragment>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                fontFamily: "Bahnschrift",
                fontWeight: "lighter",
              }}
            >
              <Button
                fullWidth
                style={{
                  backgroundColor: "#3182bd",
                  color: "white",
                  width: "5%",
                  height: "35px",
                  borderTopLeftRadius: "6px",
                  borderBottomLeftRadius: "6px",
                  borderTopRightRadius: "0px",
                  borderBottomRightRadius: "0px",
                }}
                onClick={() => {
                  setSelectedPage("STUDENT");
                }}
              >
                <ArrowBackIcon style={{ fontSize: "25px" }} />
              </Button>
              <div
                textAlign="center"
                style={{
                  color: "#084594",
                  backgroundColor: "#d7ecff",
                  width: "95%",
                  height: "35px",
                  padding: "3px 15px 0px 25px",
                  alignItems: "center",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                  borderTopRightRadius: "6px",
                  borderBottomRightRadius: "6px",
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "18px",
                }}
              >
                <span>{results[resultIndex]["test"]}</span>
                <span>{results[resultIndex]["semester"]}</span>
                <span>{results[resultIndex]["percent"]}%</span>
                <span>
                  {results[resultIndex]["marks_obtained"]} /
                  {" " + results[resultIndex]["marks_total"]}
                </span>
                {moment(
                  results[resultIndex]["start_date"],
                  "DD-MM-YYYY"
                ).format("Do MMMM YYYY") + "  "}{" "}
                to
                {"  " +
                  moment(results[resultIndex]["end_date"], "DD-MM-YYYY").format(
                    "Do MMMM YYYY"
                  )}
              </div>
            </div>
            <div style={{ margin: "-50px" }}>
              <div
                style={{ padding: "50px", marginTop: "-20px" }}
                id={"pdf-element"}
              >
                <Box p={1}>
                  <Grid
                    style={{
                      width: "100%",
                      height: "40px",
                      marginLeft: "-7px",
                      alignItems: "center",
                      borderRadius: "6px",
                      fontSize: "18px",
                      marginTop: "-20px",
                      fontFamily: "Bahnschrift",
                      justifyContent: "space-between",
                    }}
                    container
                    spacing={5}
                  >
                    <Grid item md={6}>
                      <div
                        style={{
                          marginLeft: "-40px",
                        }}
                      >
                        Subject
                      </div>
                    </Grid>
                    <Grid item justifyContent="center" md={2}>
                      <div style={{ marginLeft: "-10px" }}>Marks</div>
                    </Grid>
                    <Grid item justifyContent="center" md={3}>
                      <div
                        style={{
                          marginLeft: "0px",
                        }}
                      >
                        Date
                      </div>
                    </Grid>
                  </Grid>
                  {results[resultIndex]["results"].map(
                    (current_result, index) => (
                      <Grid
                        container
                        justifyContent="space-between"
                        spacing={5}
                        style={{ marginTop: "10px", maxHeight: "30px" }}
                      >
                        <Grid item md={6}>
                          <Box
                            justify="center"
                            style={{
                              color: "black",
                            }}
                          >
                            <b>
                              <p
                                style={{
                                  marginTop: "-10px",
                                  marginLeft: "-5px",
                                  color: "#084594",
                                }}
                              >
                                {Object.keys(current_result)[0]}
                              </p>
                            </b>
                          </Box>
                        </Grid>
                        <Grid item md={2}>
                          <Box
                            style={{
                              color: "black",
                            }}
                          >
                            <b>
                              <p
                                style={{
                                  marginTop: "-10px",
                                  color: "#084594",
                                }}
                              >
                                {
                                  current_result[
                                    Object.keys(current_result)[0]
                                  ]["marks_obtained"]
                                }{" "}
                                <span style={{ color: "#3182bd" }}> / </span>
                                {
                                  current_result[
                                    Object.keys(current_result)[0]
                                  ]["marks_total"]
                                }
                              </p>
                            </b>
                          </Box>
                        </Grid>
                        <Grid item md={3}>
                          <Box
                            style={{
                              color: "black",
                            }}
                          >
                            <b>
                              <p
                                style={{
                                  marginTop: "-10px",
                                  fontWeight: "lighter",
                                }}
                              >
                                {moment(
                                  current_result[
                                    Object.keys(current_result)[0]
                                  ]["date"],
                                  "DD-MM-YYYY"
                                ).format("Do MMMM YYYY")}
                              </p>
                            </b>
                          </Box>
                        </Grid>
                      </Grid>
                    )
                  )}
                  <Box
                    style={{
                      backgroundColor: "black",
                      marginTop: "40px",
                      marginLeft: "-35px",
                      backgroundColor: "#d7ecff",
                      padding: "10px 10px 10px 30px",
                      width: "103%",
                      maxHeight: "40px",
                      borderBottomRightRadius: "20px",
                    }}
                  >
                    <Grid
                      container
                      justifyContent="space-between"
                      spacing={5}
                      style={{ marginTop: "-58px" }}
                    >
                      <Grid item md={6}>
                        <Box
                          justify="center"
                          style={{
                            color: "black",
                          }}
                        >
                          <b>
                            <p style={{}}>Total Marks </p>
                          </b>
                        </Box>
                      </Grid>
                      <Grid item md={2}>
                        <Box
                          style={{
                            color: "black",
                          }}
                        >
                          <b>
                            <p style={{ marginLeft: "10px" }}>
                              {results[resultIndex]["marks_obtained"]} /
                              {" " + results[resultIndex]["marks_total"]}
                            </p>
                          </b>
                        </Box>
                      </Grid>
                      <Grid item md={3}>
                        <Box
                          style={{
                            color: "black",
                          }}
                        >
                          <b>
                            <p style={{ marginLeft: "63px" }}>
                              {results[resultIndex]["percent"]}%
                            </p>
                          </b>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box
                    style={{
                      width: "100%",
                      justifyContent: "space-between",
                      display: "flex",
                      marginTop: "20px",
                    }}
                  >
                    <div style={{ width: "100px" }}></div>
                    <Box
                      textAlign="center"
                      width={200}
                      height={20}
                      p={2}
                      style={{
                        color: "#31a354",
                      }}
                      borderRadius="6px"
                    >
                      <b>
                        <p
                          style={{
                            marginTop: "-12px",
                            fontSize: "20px",
                            fontFamily: "Bahnschrift",
                            letterSpacing: "3px",
                            marginLeft: "-202px",
                          }}
                        >
                          {results[resultIndex]["result"]}
                        </p>
                      </b>
                    </Box>

                    <Box
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        marginTop: "0px",
                      }}
                    >
                      <Button
                        textAlign="center"
                        style={{
                          backgroundColor: "#3182bd",
                          display: "flex",
                          color: "black",
                          padding: "5px",
                          height: "35px",
                          width: "150px",
                          borderRadius: "6px",
                          color: "white",

                          fontFamily: "Bahnschrift",
                        }}
                        borderColor="#dedede"
                        onClick={() => {
                          var element = document.getElementById("pdf-element");
                          html2pdf(element);
                        }}
                      >
                        <GetAppIcon
                          style={{
                            marginTop: "0px",
                            marginLeft: "-5px",
                          }}
                        />
                        <b
                          style={{
                            textAlign: "center",
                            margin: "3px 0px 0px 3px",
                            lineHeight: "14px",
                            fontWeight: "lighter",
                          }}
                        >
                          Download {"\n"} PDF
                        </b>
                      </Button>
                      <br></br>
                      <br></br>
                    </Box>
                  </Box>
                </Box>
              </div>
            </div>
          </Fragment>
        );
      }
    } else if (resultPage == "SUBMIT") {
      if (isLoading || !isReady) {
        return (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "white",
              marginTop: "46vh",
            }}
          >
            <CircularProgress />
          </div>
        );
      } else {
        if (selectedPage == "STUDENTS") {
          return (
            <div>
              <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#3182bd",
                    color: "white",
                    width: "5%",
                    height: "35px",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                  onClick={() => setSelectedPage("EXAMS")}
                >
                  <ArrowBackIcon style={{ fontSize: "25px" }} />
                </Button>
                <div
                  textAlign="center"
                  style={{
                    color: "#084594",
                    backgroundColor: "#d7ecff",
                    width: "95%",
                    height: "35px",
                    padding: "3px 0px 0px 25px",
                    alignItems: "center",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontFamily: "Bahnschrift",

                      marginLeft: "-15px",
                    }}
                  >
                    Add Result for {exams[selectedExam]["test"]}
                  </div>
                </div>
              </div>

              <Grid container spacing={1.5}>
                {studentList.map((student, index) => (
                  <Grid item xs={6}>
                    <CardActionArea
                      sx={[
                        {
                          borderRadius: "6px",
                          border: "1.5px solid #d7ecff",
                          transition:
                            "background-color 0.5s, border-color 0.5s",
                        },
                        (theme) => ({
                          "&:hover": {
                            backgroundColor: "#ebf5ff",
                            borderColor: "#3182bd",
                          },
                          ".MuiCardActionArea-focusHighlight": {
                            background: "transparent",
                          },

                          "&& .MuiTouchRipple-child": {
                            backgroundColor: "#084594",
                          },
                        }),
                      ]}
                      style={{
                        padding: "10px",
                        borderRadius: "6px",
                        marginBottom: "5px",
                        border: "2px solid #d7ecff",
                        borderRadius: "6px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        var tempResult = {};

                        tempResult[student["email"]] = {
                          semester: semester,
                          marks_obtained: "",
                          marks_total: exams[selectedExam]["marks_total"],
                          test: exams[selectedExam]["test"],
                          start_date: exams[selectedExam]["start_date"],
                          end_date: exams[selectedExam]["end_date"],
                          percent: "",
                          result: "",
                          results: [],
                        };
                        for (
                          var j = 0;
                          j < exams[selectedExam]["subjects"].length;
                          j++
                        ) {
                          var tempSubject = exams[selectedExam]["subjects"][j];
                          tempSubject[Object.keys(tempSubject)][
                            "marks_obtained"
                          ] = "";

                          tempResult[student["email"]]["results"].push(
                            tempSubject
                          );
                        }

                        setSubmitResult(tempResult);
                        setSelectedPage("STUDENT");
                        setStudentIndex(index);
                      }}
                    >
                      <Grid container justifyContent="space-between">
                        <Grid item md={10}>
                          <b
                            style={{
                              fontSize: "20px",
                              fontFamily: "Lato-Bold",
                              color: "#084594",
                            }}
                          >
                            {student["name"]}
                          </b>
                          <br></br>
                          <span
                            style={{
                              color: "#3182bd",
                              fontFamily: "Lato-Regular",
                              fontSize: "16px",
                            }}
                          >
                            {" "}
                            Roll no
                            <span style={{ fontFamily: "Lato-Bold" }}>
                              {" " + student["roll_number"]}
                            </span>
                          </span>
                        </Grid>

                        <Grid item md={2}>
                          {tempStudentList.includes(student["email"]) ? (
                            <ButtonBase
                              p={2}
                              value={index}
                              fullWidth
                              style={{
                                backgroundColor: "#31a354",
                                color: "white",
                                height: "25px",
                                width: "60px",
                                borderRadius: "2px",
                              }}
                              onClick={() => {
                                var tempResult = {};

                                tempResult[student["email"]] = {
                                  semester: semester,
                                  marks_obtained: "",
                                  marks_total:
                                    exams[selectedExam]["marks_total"],
                                  test: exams[selectedExam]["test"],
                                  start_date: exams[selectedExam]["start_date"],
                                  end_date: exams[selectedExam]["end_date"],
                                  percent: "",
                                  result: "",
                                  results: [],
                                };
                                for (
                                  var j = 0;
                                  j < exams[selectedExam]["subjects"].length;
                                  j++
                                ) {
                                  var tempSubject =
                                    exams[selectedExam]["subjects"][j];
                                  tempSubject[Object.keys(tempSubject)][
                                    "marks_obtained"
                                  ] = "";

                                  tempResult[student["email"]]["results"].push(
                                    tempSubject
                                  );
                                }

                                setSubmitResult(tempResult);
                                setSelectedPage("STUDENT");
                                setStudentIndex(index);
                              }}
                            >
                              <b>
                                <p
                                  style={{
                                    marginTop: "15px",
                                    fontFamily: "Bahnschrift",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  SAVED
                                </p>
                              </b>
                            </ButtonBase>
                          ) : (
                            <ButtonBase
                              p={2}
                              value={index}
                              fullWidth
                              style={{
                                backgroundColor: "#ff3333",
                                color: "white",
                                height: "25px",
                                width: "60px",
                                borderRadius: "2px",
                              }}
                              onClick={() => {
                                var tempResult = {};

                                tempResult[student["email"]] = {
                                  semester: semester,
                                  marks_obtained: "",
                                  marks_total:
                                    exams[selectedExam]["marks_total"],
                                  test: exams[selectedExam]["test"],
                                  start_date: exams[selectedExam]["start_date"],
                                  end_date: exams[selectedExam]["end_date"],
                                  percent: "",
                                  result: "",
                                  results: [],
                                };

                                for (
                                  var j = 0;
                                  j < exams[selectedExam]["subjects"].length;
                                  j++
                                ) {
                                  var tempSubject =
                                    exams[selectedExam]["subjects"][j];
                                  tempSubject[Object.keys(tempSubject)][
                                    "marks_obtained"
                                  ] = "";

                                  tempResult[student["email"]]["results"].push(
                                    tempSubject
                                  );
                                }

                                setSubmitResult(tempResult);
                                setSelectedPage("STUDENT");
                                setStudentIndex(index);
                              }}
                            >
                              <b>
                                <p
                                  style={{
                                    marginTop: "15px",
                                    fontFamily: "Bahnschrift",
                                    fontWeight: "lighter",
                                  }}
                                >
                                  SUBMIT
                                </p>
                              </b>
                            </ButtonBase>
                          )}
                        </Grid>
                      </Grid>
                    </CardActionArea>
                  </Grid>
                ))}
              </Grid>
              <br></br>
              <br></br>
              <Button
                p={2}
                style={{
                  backgroundColor: "#3283c9",
                  color: "white",
                  borderRadius: "6px",
                  height: "40px",
                  marginTop: "-5px",
                  padding: "15px",
                }}
                onClick={() => {
                  if (checkIncomplete()) {
                    setOpenIncomplete(true);
                  } else {
                    setOpen(true);
                  }
                }}
              >
                <b>PUBLISH RESULT</b>
              </Button>
            </div>
          );
        } else if (selectedPage == "EXAMS") {
          return (
            <div>
              {exams.length == 0 ? (
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>
                      No exams with pending results.
                    </h4>
                  </div>
                </div>
              ) : (
                ""
              )}
              <Grid container spacing={1.5}>
                {exams.map((exam, index) => (
                  <Fragment>
                    {!exam["completed"] ? (
                      <Grid item md={4}>
                        <Card
                          style={{
                            minHeight: "100%",
                            backgroundColor: "white",
                            boxShadow: "none",
                          }}
                          onClick={(e) => {
                            setIsReady(false);
                            setSelectedExam(index);
                            getTempResultStudents(exam["id"]);
                            setSelectedPage("STUDENTS");
                          }}
                        >
                          <CardActionArea
                            sx={[
                              {
                                borderRadius: "6px",
                                border: "1.5px solid #d7ecff",
                                transition:
                                  "background-color 0.5s, border-color 0.5s",
                              },
                              (theme) => ({
                                "&:hover": {
                                  backgroundColor: "#ebf5ff",
                                  borderColor: "#3182bd",
                                },
                                ".MuiCardActionArea-focusHighlight": {
                                  background: "transparent",
                                },

                                "&& .MuiTouchRipple-child": {
                                  backgroundColor: "#084594",
                                },
                              }),
                            ]}
                            style={{ padding: "8px 7px 12px 13px" }}
                          >
                            <h2
                              style={{
                                marginTop: "0px",
                                color: "#084594",

                                fontFamily: "Lato-Bold",
                              }}
                            >
                              {exam["test"]}
                            </h2>

                            <p
                              style={{
                                margin: "-18px 0px 15px 1px",
                                fontSize: "14px",
                                fontFamily: "Lato-Regular",
                                color: "#3182bd",
                              }}
                            >
                              {exam["semester"]}
                            </p>
                            <div
                              style={{
                                display: "flex",
                                width: "100%",
                                justifyContent: "space-between",
                              }}
                            >
                              <div
                                style={{
                                  width: "100%",
                                }}
                              >
                                <span
                                  style={{
                                    width: "20%",
                                    fontFamily: "Lato-Bold",
                                    color: "#084594",
                                  }}
                                >
                                  Start:{" "}
                                </span>
                                <span
                                  style={{
                                    margin: "0px 0px 0px 5px",
                                    fontSize: "14px",
                                    fontFamily: "Lato-Regular",
                                    width: "80%",
                                    color: "#3182bd",
                                  }}
                                >
                                  {moment(
                                    exam["start_date"],
                                    "DD-MM-YYYY"
                                  ).format("Do MMMM, YYYY")}
                                </span>
                                <div style={{ display: "flex", width: "100%" }}>
                                  <span
                                    style={{
                                      width: "20%",
                                      color: "#084594",

                                      fontFamily: "Lato-Bold",
                                    }}
                                  >
                                    End:{" "}
                                  </span>
                                  <span
                                    style={{
                                      margin: "0px 0px 0px 2.5px",
                                      fontSize: "14px",
                                      fontFamily: "Lato-Regular",
                                      width: "80%",
                                      color: "#3182bd",
                                    }}
                                  >
                                    {moment(
                                      exam["end_date"],
                                      "DD-MM-YYYY"
                                    ).format("Do MMMM, YYYY")}
                                  </span>
                                </div>
                              </div>

                              <ButtonBase
                                p={2}
                                value={index}
                                fullWidth
                                onClick={(e) => {
                                  setSelectedExam(index);
                                  setSelectedPage("STUDENTS");
                                }}
                                style={{
                                  backgroundColor: "#ff3333",
                                  color: "white",
                                  height: "25px",
                                  width: "70px",
                                  borderRadius: "2px",
                                  margin: "8px 5px 0px 5px",
                                }}
                              >
                                <b>
                                  <p
                                    style={{
                                      marginTop: "15px",
                                      fontFamily: "Bahnschrift",
                                      fontWeight: "lighter",
                                    }}
                                  >
                                    SUBMIT
                                  </p>
                                </b>
                              </ButtonBase>
                            </div>
                          </CardActionArea>
                        </Card>
                      </Grid>
                    ) : (
                      <Fragment></Fragment>
                    )}
                  </Fragment>
                ))}
              </Grid>
            </div>
          );
        } else if (selectedPage == "STUDENT") {
          return (
            <div>
              <div style={{ display: "flex", marginBottom: "15px" }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#3182bd",
                    color: "white",
                    width: "5%",
                    height: "35px",
                    borderTopLeftRadius: "6px",
                    borderBottomLeftRadius: "6px",
                    borderTopRightRadius: "0px",
                    borderBottomRightRadius: "0px",
                  }}
                  onClick={() => setSelectedPage("STUDENTS")}
                >
                  <ArrowBackIcon style={{ fontSize: "25px" }} />
                </Button>
                <div
                  textAlign="center"
                  style={{
                    color: "#084594",
                    backgroundColor: "#d7ecff",
                    width: "95%",
                    height: "35px",
                    padding: "3px 0px 0px 25px",
                    alignItems: "center",
                    borderTopLeftRadius: "0px",
                    borderBottomLeftRadius: "0px",
                    borderTopRightRadius: "6px",
                    borderBottomRightRadius: "6px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      fontSize: "20px",
                      fontFamily: "Bahnschrift",

                      marginLeft: "-15px",
                    }}
                  >
                    {studentList[studentIndex]["name"]}
                  </div>
                </div>
              </div>

              {exams[selectedExam]["subjects"].map((subject, index2) => (
                <Grid container spacing={1}>
                  <Grid item xs={8}>
                    <div
                      style={{
                        marginTop: "10px",
                        fontWeight: "bold",
                      }}
                    >
                      {Object.keys(subject)[0]}
                    </div>
                  </Grid>
                  <Grid item xs={2.5}>
                    <TextField
                      type="number"
                      value={subject[Object.keys(subject)[0]]["marks_obtained"]}
                      inputProps={{
                        min: 0,
                        style: { textAlign: "center" },
                      }}
                      style={{ marginTop: "2px" }}
                      onChange={(e) => {
                        handleMarksChange(
                          studentList[studentIndex]["email"],
                          e.target.value,
                          Object.keys(subject)[0]
                        );
                      }}
                      textAlign={"center"}
                      fullWidth
                      placeholder="Marks obtained"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={1.5}>
                    <div
                      style={{
                        marginTop: "10px",
                        fontWeight: "bold",
                        textAlign: "right",
                      }}
                    >
                      out of
                      {" " + subject[Object.keys(subject)[0]]["marks_total"]}
                    </div>
                  </Grid>
                </Grid>
              ))}
              <br></br>
              <br></br>
              <br></br>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  fontSize: "20px",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <b>Total Marks Obtained</b>
                <b>
                  {getTotalMarks()} out of {exams[selectedExam]["marks_total"]}
                </b>
              </div>
              <br></br>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  fontSize: "20px",

                  justifyContent: "space-between",
                }}
              >
                <b>Percent Obtained</b>
                <b>
                  {(
                    (getTotalMarks() /
                      parseInt(exams[selectedExam]["marks_total"])) *
                    100
                  ).toFixed(2)}
                  %
                </b>
              </div>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div></div>

                <div>
                  <span>PASSED</span>
                  <Checkbox
                    labelStyle={{ color: "#3182bd" }}
                    iconStyle={{ fill: "white" }}
                    inputStyle={{ color: "#3182bd" }}
                    style={{ marginBottom: "2px", color: "#3182bd" }}
                    checked={
                      submitResult[studentList[studentIndex]["email"]][
                        "result"
                      ] != "PASSED"
                        ? false
                        : true
                    }
                    onChange={(e) => {
                      handlePassCheck(
                        studentList[studentIndex]["email"],
                        e.target.checked
                      );
                    }}
                    value={1}
                    color={"primary"}
                  />
                </div>
              </div>
              <br></br>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {!isSaving ? (
                  <Button
                    style={{
                      fontSize: "15px",
                      height: "35px",
                      marginTop: "-1px",
                      backgroundColor: "#3182bd",
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsSaving(true);
                      saveResult(
                        exams[selectedExam]["id"],
                        studentList[studentIndex]["email"]
                      );
                    }}
                  >
                    <b>SAVE STUDENT RESULTS</b>
                  </Button>
                ) : (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      justifyContent: "center",
                      backgroundColor: "white",
                      marginTop: "46vh",
                    }}
                  >
                    <CircularProgress
                      style={{ display: "flex", justifyContent: "center" }}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        }
      }
    }
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (state.auth.expiry >= new Date()) {
          if (isLoading || !isReady) {
            return (
              <Fragment>
                <Sidebar />
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "center",
                    backgroundColor: "white",
                    marginTop: "46vh",
                  }}
                >
                  <CircularProgress />
                </div>
              </Fragment>
            );
          } else {
            return (
              <Fragment>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open}
                  onClose={() => setOpen(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                        height: "250px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isSubmitting ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <CircularProgress />{" "}
                        </div>
                      ) : (
                        <div style={{ marginTop: "-25px" }}>
                          <div
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton size="small">
                              <CloseIcon
                                onClick={() => setOpen(false)}
                                style={{ color: "#3182bd" }}
                              />
                            </IconButton>
                          </div>
                          <h3>Publish Results?</h3>
                          <span>
                            This action cannot be reversed. Results will be
                            published and notifications will be sent to all
                            parents and students of this class.
                          </span>
                          <br></br>
                          <br></br>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setIsSubmitting(true);
                                publishResults(
                                  exams[selectedExam]["id"],
                                  exams[selectedExam]["semester"]
                                );
                              }}
                              style={{ height: "30px" }}
                              variant="contained"
                            >
                              Yes
                            </Button>
                            <Button
                              style={{ height: "30px", marginLeft: "10px" }}
                              variant="contained"
                              onClick={() => setOpen(false)}
                            >
                              No
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Fade>
                </Modal>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={open2}
                  onClose={() => setOpen2(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={open2}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                        height: "250px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      {isSubmitting ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          {" "}
                          <CircularProgress />{" "}
                        </div>
                      ) : (
                        <div style={{ marginTop: "-25px" }}>
                          <div
                            style={{
                              marginTop: "20px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <IconButton size="small">
                              <CloseIcon
                                onClick={() => setOpen2(false)}
                                style={{ color: "#3182bd" }}
                              />
                            </IconButton>
                          </div>
                          <h3>Delete Results?</h3>
                          <span>
                            This action cannot be reversed. Results will be
                            deleted.
                          </span>
                          <br></br>
                          <br></br>
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              onClick={() => {
                                setIsSubmitting(true);
                                deleteResult();
                              }}
                              style={{ height: "30px" }}
                              variant="contained"
                            >
                              Yes
                            </Button>
                            <Button
                              style={{ height: "30px", marginLeft: "10px" }}
                              variant="contained"
                              onClick={() => setOpen2(false)}
                            >
                              No
                            </Button>
                          </div>
                        </div>
                      )}
                    </div>
                  </Fade>
                </Modal>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  open={openIncomplete}
                  onClose={() => setOpenIncomplete(false)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={openIncomplete}>
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        backgroundColor: "white",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        padding: "20px",
                      }}
                    >
                      <div style={{ marginTop: "-25px" }}>
                        <div
                          style={{
                            marginTop: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <IconButton size="small">
                            <CloseIcon
                              onClick={() => setOpenIncomplete(false)}
                              style={{ color: "#3182bd" }}
                            />
                          </IconButton>
                        </div>
                        <h3>Incomplete Results</h3>
                        <span>
                          All student results have not been saved. Please save
                          all students results to continue.
                        </span>
                        <br></br>
                        <br></br>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button
                            onClick={() => setOpenIncomplete(false)}
                            style={{ height: "30px" }}
                          >
                            Close
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Fade>
                </Modal>
                <Sidebar
                  tabs={
                    <Grid container spacing={1.5} justifyContent={"flex-end"}>
                      <Grid item md={4}>
                        {resultPage == "CHECK" ? (
                          <Button
                            fullWidth
                            value={"CHECK"}
                            p={2}
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              CHECK RESULTS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            fullWidth
                            value={"CHECK"}
                            onClick={() => {
                              setSelectedPage("STUDENTS");
                              setResultPage("CHECK");
                            }}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "#084594",
                              height: "30px",
                            }}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              CHECK RESULTS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                      <Grid item md={4}>
                        {resultPage == "SUBMIT" ? (
                          <Button
                            value="SUBMIT"
                            p={2}
                            fullWidth
                            style={{
                              backgroundColor: "#3182bd",
                              color: "white",
                              height: "30px",
                            }}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                              }}
                            >
                              SUBMIT RESULTS
                            </h3>
                          </Button>
                        ) : (
                          <Button
                            value="SUBMIT"
                            fullWidth
                            onClick={() => {
                              setIsSubmitting(false);
                              setIsSaving(false);
                              setIncompleteResult(false);
                              setSelectedPage("EXAMS");
                              setResultPage("SUBMIT");
                            }}
                            p={2}
                            style={{
                              backgroundColor: "#d7ecff",
                              color: "#084594",
                              height: "30px",
                            }}
                          >
                            <h3
                              style={{
                                marginTop: "17px",
                                fontSize: "15px",
                                color: "#084594",
                              }}
                            >
                              SUBMIT RESULTS
                            </h3>
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  }
                />
                <div
                  style={{
                    margin: "65px 15px 20px 85px",
                    backgroundColor: "white",
                  }}
                >
                  <Grid container spacing={1.5}>
                    <Grid item md={9}>
                      {getContent()}
                    </Grid>
                    <Grid item md={3} style={{ marginTop: "-8px" }}>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Semester
                      </span>
                      <Select
                        value={semester}
                        onChange={(e) => handleSemesterChange(e)}
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        fullWidth
                        variant={"outlined"}
                      >
                        {getSemesterList()}
                      </Select>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Class
                      </span>
                      <Select
                        value={classs}
                        onChange={(e) => handleClassChange(e)}
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        fullWidth
                        variant={"outlined"}
                      >
                        {getClassList()}
                      </Select>
                      <span
                        style={{
                          color: "#3182bd",
                          fontFamily: "Lato-Regular",
                          fontSize: "14px",
                        }}
                      >
                        Division
                      </span>
                      <Select
                        value={division}
                        onChange={(e) => handleDivisionChange(e)}
                        style={{
                          marginTop: "2px",
                          height: "40px",
                          marginBottom: "5px",
                        }}
                        fullWidth
                        variant={"outlined"}
                      >
                        {getDivisionList()}
                      </Select>
                    </Grid>
                  </Grid>
                </div>
              </Fragment>
            );
          }
        } else {
          return <Navigate to="/expired" />;
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Results;
