import { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Sidebar from "../components/Sidebar";
import Box from "@mui/material/Box";
import { API_URL, alertMessage } from "../actions/auth";
import Checkbox from "@mui/material/Checkbox";
import CheckIcon from "@mui/icons-material/Check";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import DesktopDatePicker from "@mui/lab/DesktopDatePicker";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import moment from "moment";
import IconButton from "@mui/material/IconButton";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

function Students() {
  const dispatch = useDispatch();
  function createAlert(type, title, message) {
    dispatch(alertMessage(type, title, message));
  }
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const state = useSelector((state) => state);
  const [open, setOpen] = useState(false);

  const [open1, setOpen1] = useState(false);
  const [open3, setOpen3] = useState(false);

  const colorList = ["#a1d99b", "#9ecae1", "#fdae6b"];

  const [isLoading, setIsLoading] = useState(true);

  const [leaveId, setLeaveId] = useState("");
  const [email, setEmail] = useState("");
  const [leaveDate, setLeaveDate] = useState("");
  const [profilesApproved, setProfilesApproved] = useState([]);
  const [profilesRejected, setProfilesRejected] = useState([]);
  const [profilesPending, setProfilesPending] = useState([]);
  const [currentSemester, setCurrentSemester] = useState("");
  const [classs, setClass] = useState("");
  const [noClasses, setNoClasses] = useState(false);
  const [profileIndex, setProfileIndex] = useState(0);
  const [profiles, setProfiles] = useState([]);
  const [profile, setProfile] = useState({});
  const [currentClass, setCurrentClass] = useState("");
  const [currentDivision, setCurrentDivision] = useState("");
  const [selectedApprove, setSelectedApprove] = useState(false);
  const [classList, setClassList] = useState([]);
  const [division, setDivision] = useState("");
  const [semester, setSemester] = useState("");
  const [random, setRandom] = useState(0);
  const [studentList, setStudentList] = useState([]);

  useEffect(() => {
    if (state.auth.isLoading) {
      dispatch({ type: "ENTRY_PAGE", payload: window.location.pathname });
      navigate("/", { replace: true });
    } else {
      var body = {};
      const config = {
        headers: {
          Authorization: state.auth.token,
          "X-CSRFToken": state.auth.csrfToken,
          "Content-Type": "application/json",
        },
      };
      body["institution_id"] = state.auth.user.institution_id;

      body = JSON.stringify(body);
      axios.post(API_URL + "/get_class_list", body, config).then((res) => {
        var classListTemp = res.data;

        setClassList(classListTemp);
        setClass(Object.keys(classListTemp)[0]);
        setDivision(
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
        );
        setSemester(
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ]
          )[0]
        );
        getStudents(
          Object.keys(classListTemp)[0],
          Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0],
          Object.keys(
            classListTemp[Object.keys(classListTemp)[0]][
              Object.keys(classListTemp[Object.keys(classListTemp)[0]])[0]
            ]
          )[0]
        );
      });
    }
  }, []);

  function getStudents(cla, div, sem) {
    var body = {};
    const config = {
      headers: {
        Authorization: state.auth.token,
        "X-CSRFToken": state.auth.csrfToken,
        "Content-Type": "application/json",
      },
    };
    body["class_id"] = cla;
    body["division"] = div;
    body["current_semester"] = sem;
    body["institution_id"] = state.auth.user.institution_id;

    body = JSON.stringify(body);

    axios
      .post(
        API_URL + "/get_students_list_class_division_semester",
        body,
        config
      )
      .then((res) => {
        setStudentList(res.data);
        setRandom(makeid(4));
        setIsLoading(false);
      });
  }

  if (state.auth.refresh == "REFRESH_DATA") {
    getStudents(classs, division, semester);
    dispatch({ type: "REFRESH_DATA", payload: null });
  }

  function getClassList() {
    var classes = Object.keys(classList).map((classs, index) => (
      <MenuItem value={classs}>{classs}</MenuItem>
    ));
    return classes;
  }

  function getDivisionList() {
    var classes = Object.keys(classList[classs]).map((division, index) => (
      <MenuItem value={division}>{division}</MenuItem>
    ));
    return classes;
  }

  function getSemesterList() {
    var semesters = Object.keys(classList[classs][division]).map(
      (semester, index) => <MenuItem value={semester}>{semester}</MenuItem>
    );
    return semesters;
  }

  function handleSemesterChange(e) {
    setSemester(e.target.value);
    getStudents(classs, division, e.target.value);
  }

  function handleClassChange(e) {
    setClass(e.target.value);
    setIsLoading(true);
    getStudents(e.target.value, division, semester);
  }

  function handleDivisionChange(e) {
    setDivision(e.target.value);
    setIsLoading(true);
    getStudents(classs, e.target.value, semester);
  }

  function getPageContent() {
    return (
      <Grid container spacing={1.5}>
        <Grid item md={9}>
          <Fragment>
            {studentList.length == 0 ? (
              <Grid container spacing={1.5} style={{ marginTop: "-20px" }}>
                <div
                  style={{
                    marginTop: "200px",
                    height: "100px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div style={{ textAlign: "center" }}>
                    <Fab
                      size="large"
                      style={{
                        border: "3px solid #aaa",
                        boxShadow: "none",
                        backgroundColor: "transparent",
                      }}
                      aria-label="add"
                    >
                      <PriorityHighIcon
                        style={{
                          color: "grey",
                        }}
                      />
                    </Fab>
                    <br />
                    <h4 style={{ color: "grey" }}>No profiles available.</h4>
                  </div>
                </div>
              </Grid>
            ) : (
              <Fragment>
                <Grid container spacing={1.5}>
                  {studentList.map((profile, index) => (
                    <Grid item md={3}>
                      <Box
                        onClick={() => {}}
                        style={{
                          cursor: "pointer",
                          minHeight: "100%",
                          borderRadius: "6px",
                          border: "1.3px solid #d7ecff",
                          backgroundColor: "white",
                          boxShadow: "none",
                          padding: "15px",
                        }}
                      >
                        <div
                          style={{
                            margin: "-5px 0px 0px 0px",
                            fontSize: "18px",
                            color: "#084594",
                            fontFamily: "Lato-Bold",
                          }}
                        >
                          {profile["email"].length > 25
                            ? profile["email"].substring(0, 25) + "..."
                            : profile["email"]}
                        </div>
                        <div style={{ fontWeight: "bold", fontSize: "14px" }}>
                          {profile["name"]}
                        </div>

                        <div style={{ fontSize: "14px", color: "#084594" }}>
                          {"Roll No " + profile["roll_number"]}
                        </div>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Fragment>
            )}
          </Fragment>
        </Grid>
        <Grid item md={3} style={{ marginTop: "-8px" }}>
          <span
            style={{
              color: "#3182bd",
              fontFamily: "Lato-Regular",
              fontSize: "14px",
            }}
          >
            Semester
          </span>
          <Select
            value={semester}
            onChange={(e) => handleSemesterChange(e)}
            style={{
              marginTop: "2px",
              height: "40px",
              marginBottom: "5px",
            }}
            fullWidth
            variant={"outlined"}
          >
            {getSemesterList()}
          </Select>
          <span
            style={{
              color: "#3182bd",
              fontFamily: "Lato-Regular",
              fontSize: "14px",
            }}
          >
            Class
          </span>
          <Select
            value={classs}
            onChange={(e) => handleClassChange(e)}
            style={{
              marginTop: "2px",
              height: "40px",
              marginBottom: "5px",
            }}
            fullWidth
            variant={"outlined"}
          >
            {getClassList()}
          </Select>
          <span
            style={{
              color: "#3182bd",
              fontFamily: "Lato-Regular",
              fontSize: "14px",
            }}
          >
            Division
          </span>
          <Select
            value={division}
            onChange={(e) => handleDivisionChange(e)}
            style={{
              marginTop: "2px",
              height: "40px",
              marginBottom: "5px",
            }}
            fullWidth
            variant={"outlined"}
          >
            {getDivisionList()}
          </Select>
        </Grid>
      </Grid>
    );
  }

  if (!state.auth.isLoading) {
    if (state.auth.isAuthenticated) {
      if (state.auth.user.is_manager) {
        if (isLoading) {
          return (
            <Fragment>
              <Sidebar />
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  backgroundColor: "white",
                  marginTop: "46vh",
                }}
              >
                <CircularProgress />
              </div>
            </Fragment>
          );
        } else {
          return (
            <Fragment>
              <Sidebar />

              <div
                style={{
                  margin: "65px 15px 20px 85px",
                  backgroundColor: "white",
                }}
              >
                {noClasses ? (
                  <div
                    style={{
                      marginTop: "200px",
                      height: "100px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <Fab
                        size="large"
                        style={{
                          border: "3px solid #aaa",
                          boxShadow: "none",
                          backgroundColor: "transparent",
                        }}
                        aria-label="add"
                      >
                        <PriorityHighIcon
                          style={{
                            color: "grey",
                          }}
                        />
                      </Fab>
                      <br />
                      <h4 style={{ color: "grey" }}>
                        No classes or subjects appointed.
                      </h4>
                    </div>
                  </div>
                ) : (
                  <div>
                    {noClasses ? (
                      <h3>No Classes or subjects appointed.</h3>
                    ) : (
                      getPageContent()
                    )}
                  </div>
                )}
              </div>
            </Fragment>
          );
        }
      } else {
        dispatch({
          type: "AUTH_ERROR",
        });
        return <Navigate to="/login" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          backgroundColor: "white",
          marginTop: "46vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }
}

export default Students;
